import { Button } from '@mui/material';
import type { FC } from 'react';
import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PeopleIcon from '../../assets/people.svg';
import { ReceiptBatchQuickResidentSelectionModal } from './ReceiptBatchQuickResidentSelectionModal';
import { useFormikContext } from 'formik';
import type { ReceiptBatchFormValues } from '../ReceiptBatchForm';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import type { Gender } from '@pflegenavi/shared/api';

interface ReceiptBatchQuickResidentSelectionProps {
  residents:
    | Array<{
        id: string;
        name: string;
        firstName: string;
        lastName: string;
        gender: Gender;
      }>
    | undefined;
}

export const ReceiptBatchQuickResidentSelection: FC<ReceiptBatchQuickResidentSelectionProps> =
  memo(({ residents }) => {
    const { t } = useTranslation();
    const trackEvent = useTrackStructuredEvent('ResidentQuickSelection');

    const [modalOpen, setModalOpen] = useState(false);

    const formik = useFormikContext<ReceiptBatchFormValues>();
    const {
      values: { receipts },
    } = formik;

    const [showAll, handleChangeShowAll] = React.useState(false);

    const alreadyAddedResidentsIds = useMemo(() => {
      return receipts.map((receipt) => receipt.residentId);
    }, [receipts]);

    const relevantResidents = useMemo(() => {
      if (showAll) {
        return residents;
      }
      return residents?.filter(
        (resident) => !alreadyAddedResidentsIds.includes(resident.id)
      );
    }, [alreadyAddedResidentsIds, residents, showAll]);

    return (
      <>
        <Button
          variant="outlined"
          size="small"
          startIcon={<img src={PeopleIcon} />}
          onClick={() => {
            setModalOpen(true);
            void trackEvent({
              action: 'click',
              label: 'selectResidentsButton',
            });
          }}
        >
          {t('receipts.batch.form.resident-quick-selection.select-residents')}
        </Button>
        <ReceiptBatchQuickResidentSelectionModal
          showAll={showAll}
          onChangeShowAll={handleChangeShowAll}
          open={modalOpen}
          setOpen={setModalOpen}
          residents={relevantResidents ?? []}
        />
      </>
    );
  });
