import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Alert, Link, Stack, TextField, Typography } from '@mui/material';
import { LoadingPromiseButtonAdvanced } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import {
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import PflegenaviLogo from './assets/pflegenavi_logo.svg';
import { PageContainer } from './components/PageContainer';
import { useNavigate } from 'react-router-dom';

export const ResetPassword: FC = () => {
  const { t } = useTranslation();

  const auth = useAuthentication(shouldUseNewLogin());
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const [error, setError] = useState<string | undefined>(undefined);

  const handleNavigateBackToLogin = useCallback(
    (success: boolean) => {
      navigate('/', { state: { success } });
    },
    [navigate]
  );

  const onResetPassword = useCallback(async () => {
    setError(undefined);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setError(t('authentication.errors.enter-valid-email'));
      return;
    }
    const result = await auth?.resetPassword(email);
    if (result?.ok) {
      handleNavigateBackToLogin(true);
    } else {
      setError(t('authentication.errors.something-went-wrong'));
    }
  }, [email, auth, t, handleNavigateBackToLogin]);

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onResetPassword();
          }}
        >
          <Stack gap={2} width="100%" alignItems="center">
            <Typography variant="h4" mb={-1}>
              {t('authentication.forgot-password')}
            </Typography>
            <Stack gap={1} width="100%">
              <Alert severity="info">
                {t('authentication.reset-password-explanation')}
              </Alert>
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
              <TextField
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value as string)}
                placeholder={t('common.email-address')}
                fullWidth
              />
            </Stack>
            <LoadingPromiseButtonAdvanced
              variant="contained"
              type="submit"
              disabled={email.trim().length === 0}
              onClick={onResetPassword}
              fullWidth
              sx={{
                py: 1.8,
                fontSize: 16,
              }}
            >
              {t('authentication.submit')}
            </LoadingPromiseButtonAdvanced>
          </Stack>
        </form>
        <Link
          onClick={() => handleNavigateBackToLogin(false)}
          sx={{
            cursor: 'pointer',
          }}
        >
          {t('authentication.back-to-login')}
        </Link>
      </Stack>
    </PageContainer>
  );
};
