import type { FocusEvent } from 'react';
import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  NameAvatar,
  Stack,
  TextField,
} from '@pflegenavi/web-components';

import type { FilterOptionsState, InputLabelProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getNameInitials } from '@pflegenavi/shared/utils';

export interface Resident {
  id?: string;
  label: string;
  value: string;
  gender: string;
}

export interface SelectResidentProps {
  residents: Resident[];
  autoFocus?: boolean;
  setResidentId: (residentId?: string) => void;
  residentId?: string;
  helperText?: string;
  error?: boolean;
  handleBlur?: {
    (e: FocusEvent<any, Element>): void;

    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleFocus?: (e: FocusEvent<any, Element>) => void;
  name?: string;
  disabled?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  hideAvatar?: boolean;
  InputLabelProps?: Partial<InputLabelProps>;
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SelectResident: React.FC<SelectResidentProps> = ({
  residents,
  autoFocus,
  residentId,
  setResidentId,
  disabled,
  hideAvatar,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedResident, setSelectedResident] = useState<Resident | null>(
    () => residents.find((resident) => resident.value === residentId) ?? null
  );

  useEffect(() => {
    const newSelectedResident =
      residents.find((resident) => resident.value === residentId) ?? null;
    setSelectedResident(newSelectedResident);
  }, [residentId, residents]);

  const defaultInputValue = selectedResident?.label ?? '';
  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [setInputValue, defaultInputValue]);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Resident | null
  ) => {
    setResidentId(value?.value);
  };

  return (
    <Autocomplete
      data-cy="select-resident"
      disabled={disabled}
      noOptionsText={t('residents.no-results-found')}
      options={residents}
      value={selectedResident}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={({ InputProps, InputLabelProps, ...params }) => (
        <TextField
          name={props.name}
          label={props.label ?? t('residents.select')}
          type="text"
          autoFocus={residentId ? undefined : autoFocus}
          helperText={props.helperText}
          error={props.error}
          onBlur={props.handleBlur}
          onFocus={props.handleFocus}
          onKeyUp={props.onKeyUp}
          variant={props.variant}
          InputLabelProps={{ ...props.InputLabelProps, ...InputLabelProps }}
          InputProps={{
            ...InputProps,
            startAdornment:
              !hideAvatar && selectedResident ? (
                <NameAvatar
                  name={selectedResident.label}
                  gender={selectedResident.gender}
                  sx={{
                    mr: 1,
                    height: 37,
                    width: 37,
                    zIndex: 1,
                  }}
                />
              ) : null,
          }}
          {...params}
        />
      )}
      renderOption={(props, option) => {
        return <Option option={option} {...props} key={option.id} />;
      }}
      sx={{
        minWidth: props.minWidth ?? 280,
        maxWidth: props.maxWidth,
      }}
    />
  );
};

interface IOption {
  option: Resident;
}

const Option: React.FC<IOption> = ({ option, ...props }) => {
  return (
    <li {...props}>
      <Stack direction="row" alignItems="center">
        <NameAvatar
          name={option.label}
          gender={option.gender}
          sx={{
            mr: 1,
          }}
        />
        {option.label}
      </Stack>
    </li>
  );
};

const filterOptions = (
  options: Resident[],
  state: FilterOptionsState<Resident>
) => {
  return options.filter((option) => {
    const optionLabel = option.label.toLowerCase();
    const nameInitials = getNameInitials(option.label).toLowerCase();
    const inputValue = state.inputValue.toLowerCase();

    return optionLabel.includes(inputValue) || nameInitials === inputValue;
  });
};
