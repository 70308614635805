import type {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import type { ReceiptFull } from '@pflegenavi/shared/api';
import type { ReceiptTableRowModel } from '../interfaces/ReceiptTableRowModel';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';

interface formatReceiptTableRowModelOpts {
  fDate: ReturnType<typeof useFormatDate>;
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  t: (key: string) => string;
}

export const formatReceiptTableRowModel = (
  transaction: ReceiptFull,
  opts: formatReceiptTableRowModelOpts
): ReceiptTableRowModel => {
  const residentNameString = `${transaction.resident.firstName} ${transaction.resident.lastName}`;
  const receiptTypeString = transaction.receipt_type
    ? opts.t(`receiptType.${transaction.receipt_type.name}`)
    : '--';
  const serviceProviderString = transaction.service_provider?.name || '--';
  const amountString = opts.fCurrency(transaction.amount);
  const dateString = opts.fDate(transaction.date, YEAR_MONTH_DAY_SHORT_FORMAT);
  const submissionDateString = transaction.submissionDate
    ? opts.fDate(transaction.submissionDate, YEAR_MONTH_DAY_SHORT_FORMAT)
    : undefined;
  const statusString = opts.t(`receipts.status.${transaction.status}`);

  return {
    id: transaction.id,
    residentNameString,
    residentFirstName: transaction.resident.firstName,
    residentLastName: transaction.resident.lastName,
    residentId: transaction.resident.id,
    receiptTypeString,
    serviceProviderString,
    amountString,
    amount: transaction.amount,
    dateString,
    submissionDateString,
    date: transaction.date,
    submissionDate: transaction.submissionDate,
    created_on: transaction.created_on,
    statusString,
    status: transaction.status,
    receiptImageIds: transaction.receiptImageIds,
    receipt_not_available: transaction.receipt_not_available,
    cancellation: transaction.cancellation,
  };
};
