import type { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { useCallback, useContext } from 'react';
import { FeatureFlagDevContext } from './FeatureFlagDevContext';

export const useSetFeatureFlag: () => (
  featureFlag: FeatureFlag,
  status: FeatureFlagStatus
) => void = () => {
  const context = useContext(FeatureFlagDevContext);

  return useCallback(
    (featureFlag: FeatureFlag, status: FeatureFlagStatus) => {
      context.set(new Map(context.data).set(featureFlag, status));
    },
    [context]
  );
};
