import { FeatureFlagStatus } from '@pflegenavi/shared/api';
import type { FeatureFlag } from '@pflegenavi/shared/api';
import { useFeatureFlagDev } from './useFeatureFlagDev';

export const useFeatureFlag = (featureFlag: FeatureFlag): FeatureFlagStatus => {
  // useFeatureFlagDev will return the dev feature flag if it is set. Since the user
  // can only set the dev feature flag if they have the DevTools enabled, we can
  // safely return the dev feature flag here.
  const devFeatureFlag = useFeatureFlagDev(featureFlag);

  return devFeatureFlag;
};

export const useFeatureFlagEnabled = (featureFlag: FeatureFlag): boolean => {
  return useFeatureFlag(featureFlag) === FeatureFlagStatus.Enabled;
};
