import { useEffect, useMemo, useState, useTransition } from 'react';
import type { BatchReceiptDetails } from '../ReceiptBatchForm';
import type {
  ExpandedResidentReceiptBatchEntryDto,
  UpdateReceiptBatchEntryResultDto,
} from '../../interfaces/receiptBatchEntry';
import { isValid } from 'date-fns';

export const transformBatchEntries = (
  receipts:
    | UpdateReceiptBatchEntryResultDto[]
    | ExpandedResidentReceiptBatchEntryDto[]
): BatchReceiptDetails[] => {
  // eslint-disable-next-line complexity
  return receipts.map((receipt) => {
    return {
      id: receipt.id,
      amount: receipt.amount ?? undefined,
      notes: receipt.notes ?? undefined,
      receiptImageIds: receipt.receiptImageIds ?? undefined,
      residentId:
        ('residentId' in receipt ? receipt.residentId : receipt.resident?.id) ??
        undefined,
      residentName: receipt.resident
        ? `${receipt.resident.firstName} ${receipt.resident.lastName}`
        : undefined,
      // If state is not set, we assume the resident is active
      residentActive: receipt.resident?.state
        ? receipt.resident.state === 'Active' ||
          receipt.resident.state === 'Exited'
        : true,
      individualDate: receipt.receiptDate,
    };
  });
};

interface UseGetReceiptBatchResult<T> {
  data?: T;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
  isError: boolean;
  error: unknown;
  errorUpdateCount: number;
}

interface UseThrottledReceiptsProps<T> {
  batchId: string | undefined;
  useGetReceiptBatch: (
    batchId: string | undefined
  ) => UseGetReceiptBatchResult<T>;
}

interface UseThrottledReceiptsResult<T> {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  errorUpdateCount: number;
  totalAmount: number;
  isThrottling: boolean;
  refetchBatch: () => void;
  isRefetching: boolean;
  batch?: T;
  throttledReceipts: BatchReceiptDetails[];
  totalNumberOfReceipts: number;
  minReceiptDate?: Date;
  maxReceiptDate?: Date;
}

// Because so many rows are mounted, we need to throttle the rendering of rows.
export function useThrottledReceiptBatch<
  T extends {
    receiptBatchEntries: any[];
  }
>({
  batchId,
  useGetReceiptBatch,
}: UseThrottledReceiptsProps<T>): UseThrottledReceiptsResult<T> {
  const [, startTransition] = useTransition();
  const {
    data: batch,
    isLoading,
    isRefetching,
    refetch: refetchBatch,
    isError,
    error,
    errorUpdateCount,
  } = useGetReceiptBatch(batchId);

  const [loadCount, setLoadCount] = useState(0);

  // Computing the custom format is quickly, we need to do it here to avoid re-renders
  // If we do it in throttledReceipts, the object reference changes and the rows are re-rendered
  const receipts = useMemo(() => {
    return transformBatchEntries(
      (batch?.receiptBatchEntries ??
        []) as ExpandedResidentReceiptBatchEntryDto[]
    );
  }, [batch]);

  // While all receipts are already available, we only render some of them
  // so that we have a fast initial render and avoid blocking the user
  const throttledReceipts = useMemo(() => {
    return receipts.slice(0, loadCount);
  }, [receipts, loadCount]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (batch && batch.receiptBatchEntries.length > loadCount) {
      timeout = setTimeout(() => {
        // Start transition is a React 18 feature. It indicates that the state update is low priority.
        // This means if rows are currently being mounted, the state update will be delayed until the
        // mounting is done.
        startTransition(() => {
          setLoadCount((count) => count + 6);
        });
      }, 50);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [batch, loadCount]);

  const totalAmount = useMemo(() => {
    return receipts.reduce((acc, receipt) => acc + (receipt.amount ?? 0), 0);
  }, [receipts]);

  const { minReceiptDate, maxReceiptDate } = useMemo(() => {
    const receiptDates = receipts
      .map((receipt) => {
        const date = receipt.individualDate
          ? new Date(receipt.individualDate)
          : undefined;
        return isValid(date) ? date?.getTime() : undefined;
      })
      .filter((date) => Boolean(date)) as number[];

    return {
      minReceiptDate:
        receiptDates.length > 0
          ? new Date(Math.min(...receiptDates))
          : undefined,
      maxReceiptDate:
        receiptDates.length > 0
          ? new Date(Math.max(...receiptDates))
          : undefined,
    };
  }, [receipts]);

  return {
    batch,
    totalNumberOfReceipts: receipts.length,
    totalAmount,
    isLoading,
    isError,
    error,
    errorUpdateCount,
    isRefetching,
    isThrottling: receipts.length > loadCount,
    throttledReceipts,
    refetchBatch,
    minReceiptDate,
    maxReceiptDate,
  };
}
