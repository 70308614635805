import { createContext } from 'react';
import type { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';

export const FeatureFlagDevContext = createContext<{
  data: Map<FeatureFlag, FeatureFlagStatus>;
  set: (newMap: Map<FeatureFlag, FeatureFlagStatus>) => void;
}>({
  data: new Map(),
  set: () => {
    return;
  },
});
