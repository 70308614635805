import { createMD5 } from 'hash-wasm';
import type { IHasher } from 'hash-wasm/dist/lib/WASMInterface';

// From https://stackoverflow.com/a/63287199
function hashChunk(hasher: IHasher, chunk: Blob) {
  return new Promise<void>((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      if (e.target?.result && typeof e.target.result !== 'string') {
        const view = new Uint8Array(e.target.result);
        hasher.update(view);
      }
      resolve();
    };

    fileReader.readAsArrayBuffer(chunk);
  });
}

const chunkSize = 64 * 1024 * 1024;

export const computeMd5HashOfFile = async (file: File): Promise<string> => {
  const hasher = await createMD5();

  const chunkNumber = Math.floor(file.size / chunkSize);

  for (let i = 0; i <= chunkNumber; i++) {
    const chunk = file.slice(
      chunkSize * i,
      Math.min(chunkSize * (i + 1), file.size)
    );
    await hashChunk(hasher, chunk);
  }

  const hash = hasher.digest('binary');
  return bufferToBase64(hash);
};

// From https://stackoverflow.com/a/66046174
// note: `buffer` arg can be an ArrayBuffer or a Uint6Array
async function bufferToBase64(buffer: Uint8Array) {
  // use a FileReader to generate a base64 data URI:
  const base64url = await new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.readAsDataURL(new Blob([buffer]));
  });
  // remove the `data:...;base64,` part from the start
  return base64url.slice(base64url.indexOf(',') + 1);
}
