import type { FC } from 'react';
import React from 'react';
import { useFormikContext } from 'formik';
import { Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { DatePickerQuickActions } from '@pflegenavi/web-components';

interface QuickResidentsIndividualDateSelectionProps {
  date?: Date;
  setDate: (date?: Date) => void;
}

export const QuickResidentsIndividualDateSelection: FC<
  QuickResidentsIndividualDateSelectionProps
> = ({ date, setDate }) => {
  const { t } = useTranslation();
  const dateSelectionEnabled = useFormikContext<{
    individualDatesMode: boolean;
  }>().values.individualDatesMode;

  if (!dateSelectionEnabled) {
    return null;
  }

  return (
    <Stack gap={1.5}>
      <Stack gap={0.5}>
        <Typography variant="body1" fontWeight={700}>
          {t('receipts.batch.form.resident-quick-selection.date.title')}
        </Typography>

        <Typography variant="caption" color="grey.500">
          {t('receipts.batch.form.resident-quick-selection.date.subtitle')}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          flex: 1,
          gap: 1,
        }}
      >
        <DatePicker
          label={t('common.date')}
          value={date ?? null}
          maxDate={new Date()}
          onChange={(value) => {
            setDate(value ?? undefined);
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: '',
                }}
              />
            );
          }}
        ></DatePicker>
        <DatePickerQuickActions
          name={'individual-picker'}
          setToday={setDate}
          setYesterday={setDate}
        />
      </Stack>
    </Stack>
  );
};
