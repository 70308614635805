import type { FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReceiptBatchRenderRowProps } from './ReceiptBatchReceiptsView';
import { ReceiptBatchReceiptsView } from './ReceiptBatchReceiptsView';
import type { FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Formik, useFormikContext } from 'formik';
import { Card, Stack } from '@mui/material';
import { ReceiptBatchActionButtons } from './ReceiptBatchActionButtons';
import * as Yup from 'yup';
import { endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { EnlargeImageGallery } from '@pflegenavi/transaction-components';
import type { UrlTypeNameMapping } from '@pflegenavi/web-components';
import { LoadingContainer } from '@pflegenavi/web-components';
import { useFormatting } from '@pflegenavi/frontend/localization';
import type { Gender, RecurringItemDto } from '@pflegenavi/shared/api';

import isEqual from 'lodash.isequal';
import { ReceiptBatchCommonContainer } from './ReceiptBatchCommonContainer';
import {
  MAX_ENTRY_TOTAL_AMOUNT_FOR_RECEIPT_GENERATION_IN_CENTS,
  MAX_RECEIPT_BATCH_AMOUNT_IN_CENTS,
  MAX_TRANSACTION_AMOUNT_IN_CENTS,
} from '@pflegenavi/shared/constants';
import { TestIds } from '../testIds';
import { ReceiptBatchQuickResidentSelection } from './ResidentQuickSelection/ReceiptBatchQuickResidentSelection';
import { updateReceiptBatchWithMergeBase } from './utils/useUpdateReceiptBatchWithMerge';
import { useSnackbar } from 'notistack';
import { RecurringItemAmountByDateRangeModal } from '../components/RecurringItemAmountByDateRangeModal';

export interface ReceiptBatchFormValues {
  title: string;
  receiptDate?: Date;
  batchImageIds?: string[];
  individualDatesMode: boolean;
  receipts: BatchReceiptDetails[];
}

export interface BatchReceiptDetails {
  id?: string;
  residentId?: string;
  residentName?: string;
  residentGender?: Gender;
  amount?: number;
  notes?: string;
  receiptImageIds?: string[] | null;
  residentActive?: boolean;
  individualDate?: Date;
}

export interface UsePreviewImageIdsReturnType {
  setPreviewImageIds: (imgIds: string[] | undefined) => void;
  enlargeImageGalleryProps: {
    initialSelectedIndex: number;
    handleClose: () => void;
    imageUrls: string[];
    imageUrlToTitleMap: Map<string, string | undefined>;
    loading: false | true;
    open: boolean;
    imageUrlToTypeMap: UrlTypeNameMapping;
  };
  setTitleForImage: (id: string, title: string | undefined) => void;
  setShownImageId: (imgId: string | undefined) => void;
}

interface ReceiptBatchFormProps<T extends ReceiptBatchFormValues> {
  initialValues: T;
  archivedResidents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  defaultInitialValues?: T;
  // the schema for the receipt batch is mostly the same except few fields, we pass this specific schema and combine it with common schema
  validationSchemaExtension: any;
  handleSubmit: (values: T, helpers: FormikHelpers<T>) => void;
  handleDeleteBatch?: VoidFunction;
  blockSubmit?: boolean;
  isLoading?: boolean;
  allowNavigateAway: boolean;
  usePreviewImageIds: () => UsePreviewImageIdsReturnType;
  useRenderReceiptBatchRow: (autoGeneratedReceipts: boolean) => {
    isLoading: boolean;
    renderRow: (props: ReceiptBatchRenderRowProps) => JSX.Element;
    residents?: Array<{
      id: string;
      name: string;
      firstName: string;
      lastName: string;
      gender: Gender;
    }>;
  };
  renderReceiptBatchCommonForm: (
    formik: FormikProps<T>,
    recurringItem: RecurringItemDto | undefined
  ) => React.ReactElement;
  renderReceiptBatchImageInput: (formik: FormikProps<T>) => React.ReactElement;
  renderCashLink?: (formik: FormikProps<T>) => React.ReactElement;
  onFormReset?: VoidFunction;
  unblockRef?: React.MutableRefObject<() => void>;
  recurringItem?: RecurringItemDto;
  autoGeneratedReceipts: boolean;
}

export interface RecurringItemCalculationData {
  entryId: string | undefined;
  index: number | undefined;
  amount: number;
}

// eslint-disable-next-line complexity
function ReceiptBatchForm<T extends ReceiptBatchFormValues>({
  initialValues,
  archivedResidents,
  defaultInitialValues,
  handleSubmit,
  handleDeleteBatch,
  validationSchemaExtension,
  blockSubmit,
  isLoading,
  allowNavigateAway,
  useRenderReceiptBatchRow,
  usePreviewImageIds,
  renderReceiptBatchImageInput,
  renderReceiptBatchCommonForm,
  renderCashLink,
  onFormReset,
  unblockRef,
  recurringItem,
  autoGeneratedReceipts,
}: ReceiptBatchFormProps<T>): React.ReactElement {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [
    recurringItemCalculationModalOpen,
    setRecurringItemCalculationModalOpen,
  ] = useState(false);

  const [recurringItemCalculationData, setRecurringItemCalculationData] =
    useState<RecurringItemCalculationData>({
      entryId: undefined,
      amount: 0,
      index: undefined,
    });

  const useRecurringItemEditableAmount = recurringItem;

  const handleEntryRecurringItemCalculation = useCallback(
    (entryId: string, index: number, amount: number) => {
      setRecurringItemCalculationData({
        entryId,
        amount,
        index,
      });
      setRecurringItemCalculationModalOpen(true);
    },
    []
  );

  const archivedResidentIds = useMemo(() => {
    return archivedResidents?.map((r) => r.id) ?? [];
  }, [archivedResidents]);

  const {
    setPreviewImageIds,
    enlargeImageGalleryProps,
    setShownImageId,
    setTitleForImage,
  } = usePreviewImageIds();

  const {
    renderRow,
    isLoading: isLoadingResidents,
    residents,
  } = useRenderReceiptBatchRow(autoGeneratedReceipts);

  const finalInitialValues = defaultInitialValues ?? initialValues;

  const internalIsLoading = Boolean(isLoading);
  const internalBlockSubmit = Boolean(blockSubmit) || isLoadingResidents;

  const entryAmountMaxValidation: {
    amount: number;
    message: string;
  } = useMemo(() => {
    if (autoGeneratedReceipts) {
      return {
        amount: MAX_ENTRY_TOTAL_AMOUNT_FOR_RECEIPT_GENERATION_IN_CENTS,
        message: t('receipts.batch.form.amount-max-receipt-gen', {
          value: fCurrencyCents(
            MAX_ENTRY_TOTAL_AMOUNT_FOR_RECEIPT_GENERATION_IN_CENTS
          ),
        }),
      };
    }
    return {
      amount: MAX_TRANSACTION_AMOUNT_IN_CENTS,
      message: t('field.max-value', {
        value: fCurrencyCents(MAX_TRANSACTION_AMOUNT_IN_CENTS),
      }),
    };
  }, [autoGeneratedReceipts, fCurrencyCents, t]);

  const getReceiptEntriesValidationSchema = (individualDatesMode: boolean) =>
    Yup.array().of(
      Yup.object().shape({
        residentId: Yup.string()
          .required(t('field.select-resident'))
          .notOneOf(archivedResidentIds, t('field.resident-archived')),
        amount: Yup.number()
          .min(1, t('field.positive-value'))
          .max(
            entryAmountMaxValidation.amount,
            entryAmountMaxValidation.message
          )
          .required(t('field.amount'))
          .transform((_, val) => (val === Number(val) ? val : null)),
        notes: Yup.string().max(255, t('receipts.batch.form.note-max-length')),
        receiptImageIds: Yup.array().nullable(),
        ...(individualDatesMode
          ? {
              individualDate: Yup.date()
                .required(t('field.select-date'))
                .max(endOfDay(new Date()), t('field.date-past'))
                .typeError(t('field.valid-date')),
            }
          : {}),
      })
    );

  const validationSchema = Yup.object({
    receiptDate: Yup.date().when('individualDatesMode', {
      is: (individualDatesMode: boolean) => individualDatesMode,
      then: Yup.date().notRequired(),
      otherwise: Yup.date()
        .required(t('field.select-date'))
        .max(endOfDay(new Date()), t('field.date-past'))
        .typeError(t('field.valid-date')),
    }),
    individualDatesMode: Yup.boolean(),
    receipts: Yup.array().when('individualDatesMode', {
      is: (individualDatesMode: boolean) => individualDatesMode,
      then: getReceiptEntriesValidationSchema(true),
      otherwise: getReceiptEntriesValidationSchema(false),
    }),
  }).concat(validationSchemaExtension);

  const [isReceiptsAmountSumOverLimit, setIsReceiptsAmountSumOverLimit] =
    useState(false);

  useEffect(() => {
    if (isReceiptsAmountSumOverLimit) {
      enqueueSnackbar(
        t('receipts.batch.form.amount-sum-over-limit', {
          maxAmount: fCurrencyCents(MAX_RECEIPT_BATCH_AMOUNT_IN_CENTS),
        }),
        {
          variant: 'error',
          persist: true,
          preventDuplicate: true,
          key: 'receipts-batch-form-amount-sum-over-limit',
        }
      );
    } else {
      closeSnackbar('receipts-batch-form-amount-sum-over-limit');
    }
    return () => {
      closeSnackbar('receipts-batch-form-amount-sum-over-limit');
    };
  }, [
    closeSnackbar,
    enqueueSnackbar,
    fCurrencyCents,
    isReceiptsAmountSumOverLimit,
    t,
  ]);

  const [isFinalLoading, setIsFinalLoading] = useState(true);

  useEffect(() => {
    // The form state is synced in FormikObserver. However, this causes state to be updated
    // lazily - one render update after internalIsLoading turns false.
    // This hook is here to delay the loading state to avoid flickering
    if (!internalIsLoading) {
      setTimeout(() => {
        setIsFinalLoading(false);
      }, 0);
    }
  }, [internalIsLoading]);

  const showRows =
    !isLoadingResidents &&
    ((residents !== undefined && residents.length > 0) ||
      finalInitialValues.receipts.length > 0);

  return (
    <>
      {internalIsLoading && (
        <Card
          sx={{
            p: 3,
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadingContainer />
        </Card>
      )}
      <Formik
        initialValues={finalInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize={false}
        validateOnChange
        validateOnBlur
        validateOnMount={false}
        onReset={onFormReset}
      >
        <Stack
          sx={{ visibility: isFinalLoading ? 'hidden' : undefined }}
          data-testid={TestIds.ReceiptBatchForm}
        >
          <Stack direction="column" gap={2}>
            <Stack>
              <ReceiptBatchCommonContainer
                renderReceiptBatchCommonForm={renderReceiptBatchCommonForm}
                renderReceiptBatchImageInput={renderReceiptBatchImageInput}
                renderCashLink={renderCashLink}
                recurringItem={recurringItem}
              />
            </Stack>
            <ScrollToFieldError />
            <ReceiptsAmountSumLimitObserver
              setIsReceiptsAmountSumOverLimit={setIsReceiptsAmountSumOverLimit}
            />
            {useRecurringItemEditableAmount && (
              <RecurringItemAmountByDateRangeModal
                key={recurringItemCalculationModalOpen ? 'open' : 'closed'}
                open={recurringItemCalculationModalOpen}
                setOpen={setRecurringItemCalculationModalOpen}
                {...recurringItemCalculationData}
                recurringItem={recurringItem}
                receiptBatchDate={finalInitialValues.receiptDate ?? new Date()}
                originalAmount={recurringItemCalculationData.amount / 100}
                setRecurringItemCalculationData={
                  setRecurringItemCalculationData
                }
              />
            )}
            {showRows && (
              <Stack>
                <ReceiptBatchReceiptsView
                  renderRow={renderRow}
                  setShownImageId={setShownImageId}
                  setPreviewImageIds={setPreviewImageIds}
                  archivedResidents={archivedResidents}
                  ReceiptBatchQuickResidentSelection={
                    recurringItem ? null : (
                      <ReceiptBatchQuickResidentSelection
                        residents={residents}
                      />
                    )
                  }
                  handleEntryRecurringItemCalculation={
                    useRecurringItemEditableAmount
                      ? handleEntryRecurringItemCalculation
                      : undefined
                  }
                />
              </Stack>
            )}
            <FormikObserver
              residents={residents}
              setPreviewImageIds={setPreviewImageIds}
              setTitleForImage={setTitleForImage}
              initialValues={finalInitialValues}
            />
            <EnlargeImageGallery {...enlargeImageGalleryProps} />
          </Stack>
          <ReceiptBatchActionButtons
            unblockRef={unblockRef}
            blockSubmit={internalBlockSubmit || isReceiptsAmountSumOverLimit}
            allowNavigateAway={allowNavigateAway}
            handleDeleteBatch={handleDeleteBatch}
          />
        </Stack>
      </Formik>
    </>
  );
}

interface ReceiptsAmountSumLimitObserverProps {
  setIsReceiptsAmountSumOverLimit: (isOverLimit: boolean) => void;
}

const ReceiptsAmountSumLimitObserver = ({
  setIsReceiptsAmountSumOverLimit,
}: ReceiptsAmountSumLimitObserverProps) => {
  const {
    values: { receipts },
  } = useFormikContext<ReceiptBatchFormValues>();

  const sum = useMemo(() => {
    return receipts.reduce((acc, curr) => {
      if (curr.amount === undefined) {
        return acc;
      }
      return acc + curr.amount;
    }, 0);
  }, [receipts]);

  useEffect(() => {
    if (sum > MAX_RECEIPT_BATCH_AMOUNT_IN_CENTS) {
      setIsReceiptsAmountSumOverLimit(true);
    } else {
      setIsReceiptsAmountSumOverLimit(false);
    }
  }, [setIsReceiptsAmountSumOverLimit, sum]);

  return null;
};

export const getFieldErrorNames = (
  formikErrors: FormikErrors<ReceiptBatchFormValues>
): string[] => {
  const transformObjectToDotNotation = (
    obj: FormikErrors<ReceiptBatchFormValues>,
    prefix = '',
    result: string[] = []
  ) => {
    Object.keys(obj).forEach((key: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = obj[key];
      if (!value) {
        return;
      }

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ScrollToFieldErrorProps {}

export const ScrollToFieldError: FC<ScrollToFieldErrorProps> = () => {
  const { submitCount, isValid, errors } =
    useFormikContext<ReceiptBatchFormValues>();

  useEffect(() => {
    if (isValid) {
      return;
    }

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) {
      return;
    }

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) {
      return;
    }

    // Scroll to first known error into view
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, [submitCount]); // Errors and isValid are not dependencies on purpose, we only want to scroll when submit count changes

  return null;
};

interface ImageTitleFormObserverProps<T extends ReceiptBatchFormValues> {
  setPreviewImageIds: (imageIds: string[] | undefined) => void;
  setTitleForImage: (imageId: string, title: string | undefined) => void;
  residents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  initialValues?: T;
}

// A hack to allow us access to the formik context
function FormikObserver<T extends ReceiptBatchFormValues>({
  setTitleForImage,
  residents,
  initialValues,
}: ImageTitleFormObserverProps<T>) {
  const { fCurrencyCents } = useFormatting();
  const { values, setValues, resetForm } = useFormikContext<T>();

  const receipts = values.receipts;

  const [oldInitialValues, setOldInitialValues] = useState(initialValues);

  useEffect(() => {
    if (isEqual(initialValues, oldInitialValues)) {
      return;
    }

    if (isEqual(oldInitialValues, values)) {
      resetForm({ values: initialValues });
      setOldInitialValues(initialValues);
      return;
    }

    setValues((values) => {
      const newReceipts = updateReceiptBatchWithMergeBase({
        oldBatch: oldInitialValues?.receipts ?? [],
        newBatch: initialValues?.receipts ?? [],
        entries: values?.receipts ?? [],
      });

      setOldInitialValues(initialValues);

      const newState = {
        ...values,
        ...initialValues,
        receipts: newReceipts,
      };

      if (isEqual(newState, initialValues)) {
        resetForm({ values: newState });
      }

      return newState;
    });
    // TODO: oldInitialValues intentionally left out?
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, [initialValues, setValues, resetForm]);

  // eslint-disable-next-line complexity
  useMemo(() => {
    const textSeparator = ' - ';
    for (let i = 0; i < receipts.length; i++) {
      const receipt = receipts[i];

      for (const imageId of receipt.receiptImageIds ?? []) {
        const amount = receipt.amount;
        const resident = receipt.residentId
          ? residents?.find((r) => r.id === receipt.residentId)
          : undefined;

        const titleElements = [`#${i + 1}`];

        if (resident) {
          // Separator between number and resident name
          titleElements.push(textSeparator);
          titleElements.push(resident.name);
        }
        if (amount !== undefined && !Number.isNaN(amount)) {
          titleElements.push(textSeparator);
          titleElements.push(fCurrencyCents(amount));
        }
        const title = titleElements.join(' ');

        setTitleForImage(imageId, title);
      }
    }
  }, [fCurrencyCents, receipts, setTitleForImage, residents]);

  return null;
}

export { ReceiptBatchForm };
