import {
  Card,
  type CardProps,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { memo, useMemo } from 'react';
import {
  Iconify,
  Label,
  OneLineTypography,
  SvgIconStyle,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import ServiceProviderSvgIcon from '../assets/service_provider_icon.svg';
import { PaymentStatus } from '@pflegenavi/shared/api';

export interface ReceiptBatchMangopayPaymentCardProps {
  paidAmountInCents: number;
  pendingAmountInCents: number;
  pendingCount: number;
  serviceProviderName?: string;
  nursingHomeName?: string;
  initiatorName?: string;
  isLoading?: boolean;
  sx?: CardProps['sx'];
}

export const ReceiptBatchMangopayPaymentCard: FC<ReceiptBatchMangopayPaymentCardProps> =
  memo(({ sx, ...props }) => {
    return (
      <Card sx={{ p: 2, ...sx }}>
        <Stack gap={2}>
          <Header />
          <Divider
            sx={{
              // Counter the card padding
              ml: -2,
              mr: -2,
            }}
          />
          <Content {...props} />
        </Stack>
      </Card>
    );
  });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" gap={1.5} alignItems="center">
        <Stack
          sx={{
            backgroundColor: 'grey.300',
            borderRadius: 2,
          }}
          justifyContent="center"
          alignItems="center"
          height={40}
          width={40}
        >
          <Iconify width={24} height={24} icon={'mdi:receipt-text'} />
        </Stack>
        <Stack gap={0.5}>
          <OneLineTypography variant="subtitle2" fontWeight={700}>
            {t('receipts.batch.payment-card.title-mangopay')}
          </OneLineTypography>
          <OneLineTypography fontSize={14} color="grey.600">
            {t('receipts.batch.payment-card.subtitle')}
          </OneLineTypography>
        </Stack>
      </Stack>
    </Stack>
  );
};

type ContentProps = Pick<
  ReceiptBatchMangopayPaymentCardProps,
  | 'paidAmountInCents'
  | 'pendingAmountInCents'
  | 'pendingCount'
  | 'nursingHomeName'
  | 'serviceProviderName'
  | 'initiatorName'
  | 'isLoading'
>;

const Content: FC<ContentProps> = ({
  paidAmountInCents,
  pendingAmountInCents,
  pendingCount,
  nursingHomeName,
  serviceProviderName,
  initiatorName,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const paymentStatus = useMemo(() => {
    return pendingCount > 0 ? 'PartiallyPaid' : PaymentStatus.Completed;
  }, [pendingCount]);

  return (
    <Stack gap={1.5}>
      <Row
        isLoading={isLoading}
        icon={<StatusIcon />}
        label={t('receipts.batch.payment-card.status-title')}
        value={<StatusLabel state={paymentStatus} />}
      />
      <Row
        isLoading={isLoading}
        icon={<CashIcon />}
        label={t('receipts.batch.payment-card.paid-amount')}
        value={
          <Typography
            fontSize={14}
            color="success.main"
            sx={{ fontWeight: 700 }}
            textAlign="right"
          >
            {fCurrencyCents(paidAmountInCents)}
          </Typography>
        }
      />

      {pendingCount > 0 && (
        <Row
          isLoading={isLoading}
          icon={<ReceiptIcon />}
          label={t('receipts.batch.payment-card.unpaid-amount')}
          value={
            <Typography
              fontSize={14}
              color="warning.dark"
              sx={{ fontWeight: 700, minWidth: 170, ml: '-25px' }}
              textAlign="right"
            >
              {fCurrencyCents(pendingAmountInCents)} (
              {t('receipts.batch.payment-card.unpaid-receipt', {
                count: pendingCount,
              })}
              )
            </Typography>
          }
        />
      )}

      {serviceProviderName && (
        <Row
          isLoading={isLoading}
          icon={<ServiceProviderIcon />}
          label={t('receipts.batch.payment-card.service-provider')}
          value={
            <Typography fontSize={14} color="text.primary" textAlign="right">
              {serviceProviderName}
            </Typography>
          }
        />
      )}
      {nursingHomeName && (
        <Row
          isLoading={isLoading}
          icon={<NursingHomeIcon />}
          label={t('receipts.batch.payment-card.nursing-home')}
          value={
            <Typography fontSize={14} color="text.primary" textAlign="right">
              {nursingHomeName}
            </Typography>
          }
        />
      )}
      {initiatorName && (
        <Row
          isLoading={isLoading}
          icon={<EmployeeIcon />}
          label={t('receipts.batch.payment-card.initiator')}
          value={
            <Typography fontSize={14} color="text.primary" textAlign="right">
              {initiatorName}
            </Typography>
          }
        />
      )}
    </Stack>
  );
};

interface RowProps {
  icon: ReactNode;
  label: ReactNode;
  value: ReactNode;
  isLoading?: boolean;
}

const Row: FC<RowProps> = ({ icon, label, value, isLoading }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack width={18}>{icon}</Stack>
        <OneLineTypography fontSize={14} color="text.primary">
          {label}
        </OneLineTypography>
      </Stack>
      {isLoading && (
        <Typography fontSize={14} color="text.primary">
          <Skeleton variant="text" width={100} />
        </Typography>
      )}
      {!isLoading && value}
    </Stack>
  );
};

const StatusIcon = (): JSX.Element => {
  return <Iconify sx={{ mt: '-2px' }} icon="mdi:cog" color="grey.600" />;
};

const CashIcon = () => {
  return (
    <Iconify
      sx={{ mt: '-2px' }}
      icon="majesticons:euro-circle"
      color="grey.600"
    />
  );
};

const ReceiptIcon = () => {
  return (
    <Iconify sx={{ mt: '-1px' }} icon={'mdi:receipt-text'} color="grey.600" />
  );
};

const NursingHomeIcon = () => {
  return <Iconify sx={{ mt: '-2px' }} icon="mdi:home" color="grey.600" />;
};

const ServiceProviderIcon = () => {
  const theme = useTheme();
  return (
    <SvgIconStyle
      src={ServiceProviderSvgIcon}
      sx={{
        width: 16,
        height: 16,
        ml: '2px',
        mr: '-1px',
        mt: '-4px',
        color: theme.palette.grey['600'],
      }}
    />
  );
};

const EmployeeIcon = () => {
  return (
    <Iconify sx={{ mt: '-4px' }} icon="eva:person-fill" color="grey.600" />
  );
};

interface StatusLabel {
  state: PaymentStatus | 'PartiallyPaid';
}

const StatusLabel: FC<StatusLabel> = ({ state }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line complexity
  const color = useMemo(() => {
    switch (state) {
      case PaymentStatus.Pending:
        return 'info';
      case PaymentStatus.Completed:
        return 'success';
      case 'PartiallyPaid':
        return 'warning';
      case PaymentStatus.Failed:
        return 'error';
      case PaymentStatus.Disputed:
        return 'error';
      default:
        return 'default';
    }
  }, [state]);

  return (
    <Label color={color}>
      {t(`receipts.batch.payment-card.status.${state}`)}
    </Label>
  );
};
