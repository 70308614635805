import { Iconify, Tooltip } from '@pflegenavi/web-components';
import { IconButton } from '@mui/material';

interface CustomTooltipProps {
  title: string;
  color: string;
  onClick?: VoidFunction;
  icon: 'remove' | 'check';
}

export const IconTooltip = ({
  title,
  color,
  onClick,
  icon,
}: CustomTooltipProps): JSX.Element => {
  return (
    <Tooltip title={title}>
      <IconButton
        onClick={onClick}
        style={{
          padding: 0,
        }}
      >
        <Iconify
          icon={`mdi:receipt-text-${icon}`}
          color={color}
          fontSize={28}
        />
      </IconButton>
    </Tooltip>
  );
};
