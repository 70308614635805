import type { FC } from 'react';
import React from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { PageContainer } from './components/PageContainer';
import PflegenaviLogo from './assets/pflegenavi_logo.svg';
import { useTranslation } from 'react-i18next';

/**
 * This screen indicates to the user that they should check their emails
 * for a welcome email to verify their email address.
 */
export const VerifyEmail: FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <Stack gap={2} width="100%" alignItems="center">
          <Typography variant="h4" mb={-1}>
            {t('authentication.verify-email-title')}
          </Typography>
          <Alert severity="info">{t('authentication.verify-email')}</Alert>
        </Stack>
      </Stack>
    </PageContainer>
  );
};
