import type { FC } from 'react';
import React, { useCallback, useEffect, useReducer } from 'react';
import {
  initializeResidentQuickSelectionState,
  quickResidentsSelectionReducer,
} from './quickResidentSelectionReducer';
import { Button, Divider, Stack } from '@mui/material';
import { SearchField } from '@pflegenavi/web-components';
import {
  selectFilteredSelected,
  selectLeftArrowDisabled,
  selectNonSelected,
  selectRightArrowDisabled,
} from './selectors';
import {
  NonSelectedResidents,
  SelectAllResidentsCheckbox,
  SelectedResidentsCounterHeader,
  SelectedResidentsList,
} from './ResidentsLists';
import { SelectionArrowButtons } from './SelectionArrowButtons';
import { ReceiptAmountInput } from './ReceiptAmountInput';
import { useTranslation } from 'react-i18next';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';
import { ShowAllResidents } from './ShowAllResidents';
import { QuickResidentsIndividualDateSelection } from './QuickResidentsIndividualDateSelection';

export interface ResidentType {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
}

interface ReceiptBatchQuickResidentSelectionFormProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  residents: ResidentType[];
  onConfirm: (
    residents: ResidentType[],
    receiptAmount: number | undefined,
    date?: Date
  ) => void;
  showAll: boolean;
  onChangeShowAll: (showAll: boolean) => void;
}

export const ReceiptBatchQuickResidentSelectionForm: FC<
  ReceiptBatchQuickResidentSelectionFormProps
> = ({ setOpen, residents, onConfirm, showAll, onChangeShowAll }) => {
  const { t } = useTranslation();
  const trackEvent = useTrackStructuredEvent('ResidentQuickSelection');

  const [state, dispatch] = useReducer(
    quickResidentsSelectionReducer,
    residents,
    initializeResidentQuickSelectionState
  );

  useEffect(() => {
    dispatch({
      type: 'UPDATE_RESIDENTS',
      payload: residents,
    });
  }, [residents, showAll]);

  const {
    searchTerm,
    staged,
    stagedForDeselection,
    selected,
    filteredNonSelected,
    filteredSelected,
    receiptAmount,
  } = state;

  const handleSearchChange = useCallback(
    (term: string) => {
      dispatch({
        type: 'SET_SEARCH_TERM',
        payload: term,
      });
    },
    [dispatch]
  );

  const handleStageResident = useCallback(
    (resident: ResidentType) => {
      dispatch({
        type: 'STAGE_RESIDENT',
        payload: resident,
      });
    },
    [dispatch]
  );

  const handleStageAllNonSelected = useCallback(() => {
    dispatch({
      type: 'STAGE_ALL_NONSELECTED',
    });
  }, [dispatch]);

  const handleUnStageResident = useCallback(
    (resident: ResidentType) => {
      dispatch({
        type: 'UNSTAGE_RESIDENT',
        payload: resident,
      });
    },
    [dispatch]
  );

  const handleUnStageAll = useCallback(() => {
    dispatch({
      type: 'UNSTAGE_ALL',
    });
  }, [dispatch]);

  const handleStageForDeselection = useCallback(
    (resident: ResidentType) => {
      dispatch({
        type: 'STAGE_FOR_DESELECTION',
        payload: resident,
      });
    },
    [dispatch]
  );

  const handleUnStageForDeselection = useCallback(
    (resident: ResidentType) => {
      dispatch({
        type: 'UNSTAGE_FOR_DESELECTION',
        payload: resident,
      });
    },
    [dispatch]
  );

  const setDate = useCallback(
    (date?: Date) => {
      dispatch({
        type: 'SET_DATE',
        payload: date,
      });
    },
    [dispatch]
  );

  const handleMoveStagedToSelected = useCallback(() => {
    dispatch({ type: 'MOVE_STAGED_TO_SELECTED' });
  }, [dispatch]);

  const handleMoveStagedForDeselectionToNonSelected = useCallback(() => {
    dispatch({ type: 'MOVE_STAGED_FOR_DESELECTION_TO_NON_SELECTED' });
    void trackEvent({
      action: 'click',
      label: 'deselectResidents',
    });
  }, [dispatch, trackEvent]);

  const handleSetReceiptAmount = useCallback(
    (amount: number) => {
      dispatch({
        type: 'SET_RECEIPT_AMOUNT',
        payload: amount,
      });
    },
    [dispatch]
  );

  const handleResetReceiptAmount = useCallback(() => {
    dispatch({ type: 'RESET_RECEIPT_AMOUNT' });
  }, [dispatch]);

  return (
    <>
      <Stack gap={5} direction="row" justifyContent="space-between">
        <Stack
          gap={4}
          direction="row"
          justifyContent="space-between"
          sx={{
            minHeight: '70%',
            width: '65%',
          }}
        >
          <Stack gap={1} sx={{ width: '45%' }}>
            <SelectedResidentsCounterHeader
              title={t(
                'receipts.batch.form.resident-quick-selection.available-residents'
              )}
              count={staged.length}
            />
            <SearchField
              size="small"
              placeholder={t(
                'receipts.batch.form.resident-quick-selection.search-for-resident'
              )}
              value={searchTerm}
              onChangeValue={handleSearchChange}
            />
            <NonSelectedResidents
              filteredNonSelected={filteredNonSelected}
              staged={staged}
              handleStageResident={handleStageResident}
              handleUnStageResident={handleUnStageResident}
            />
            <Stack>
              <SelectAllResidentsCheckbox
                checkboxKey={selected.length.toString()}
                handleStageAllNonSelected={handleStageAllNonSelected}
                handleUnStageAll={handleUnStageAll}
                disabled={selectNonSelected(state).length === 0}
              />
              <ShowAllResidents
                onChangeShowAll={onChangeShowAll}
                showAll={showAll}
              />
            </Stack>
          </Stack>
          <SelectionArrowButtons
            disabledRight={selectRightArrowDisabled(state)}
            disabledLeft={selectLeftArrowDisabled(state)}
            onClickLeft={handleMoveStagedForDeselectionToNonSelected}
            onClickRight={handleMoveStagedToSelected}
          />
          <Stack gap={1} sx={{ width: '45%' }}>
            <SelectedResidentsList
              selected={selected}
              filteredSelected={filteredSelected}
              stagedForDeselection={stagedForDeselection}
              handleStageForDeselection={handleStageForDeselection}
              handleUnStageForDeselection={handleUnStageForDeselection}
            />
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />

        <Stack sx={{ width: '30%' }} gap={4}>
          <QuickResidentsIndividualDateSelection
            date={state.date}
            setDate={setDate}
          />

          <ReceiptAmountInput
            value={receiptAmount}
            handleSetReceiptAmount={handleSetReceiptAmount}
            handleResetReceiptAmount={handleResetReceiptAmount}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
      >
        <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('receipts.batch.form.resident-quick-selection.cancel')}
        </Button>
        <Button
          disabled={selectFilteredSelected(state).length === 0}
          variant="contained"
          onClick={() => {
            onConfirm(selected, receiptAmount, state.date);
            setOpen(false);
          }}
        >
          {t('receipts.batch.form.resident-quick-selection.save')}
        </Button>
      </Stack>
    </>
  );
};
