import * as React from 'react';
import { Grid } from '@mui/material';
import { ReceiptBatchOverview } from './ReceiptBatchOverview';

interface ReceiptBatchCommonViewProps {
  receiptsCount: number;
  totalAmount: number;
  ReceiptBatchCommonForm: React.ReactElement;
  ReceiptBatchImageInput: React.ReactElement;
  CashLink: React.ReactElement | null;
}

export function ReceiptBatchV2CommonView({
  ReceiptBatchCommonForm,
  receiptsCount,
  totalAmount,
  ReceiptBatchImageInput,
  CashLink,
}: ReceiptBatchCommonViewProps): JSX.Element {
  return (
    <Grid container spacing={2} alignItems="flex-start">
      {ReceiptBatchCommonForm}
      <ReceiptBatchOverview
        receiptsCount={receiptsCount}
        totalAmount={totalAmount}
        ReceiptBatchImageInput={ReceiptBatchImageInput}
      />
      {CashLink}
    </Grid>
  );
}
