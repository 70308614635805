import type { FC } from 'react';
import React from 'react';
import { useUrlsToImages } from '@pflegenavi/shared-frontend/platform';
import {
  type UrlTypeNameMapping,
  useFileUrlToTypeMapping,
} from '@pflegenavi/web-components';

import { useReceiptImages } from '@pflegenavi/frontend/api-nursing-home';
import { EnlargeImageGallery } from '../EnlargeImageGallery';

interface ReceiptBatchTableEnlargeGalleryProps {
  openImageGallery: boolean;
  setOpenImageGallery: (open: boolean) => void;
  batchImageIds: string[] | undefined;
  title: string;
  generatedReceiptImageUrls: string[];
  setGeneratedReceiptImageUrls: React.Dispatch<React.SetStateAction<string[]>>;
  generatedReceiptTypeMap: UrlTypeNameMapping;
}

export const ReceiptBatchTableEnlargeGallery: FC<
  ReceiptBatchTableEnlargeGalleryProps
> = ({
  batchImageIds,
  openImageGallery,
  setOpenImageGallery,
  title,

  generatedReceiptImageUrls,
  setGeneratedReceiptImageUrls,
  generatedReceiptTypeMap,
}) => {
  const { receiptImages: receiptImageUrls, ImageUrlsLoading } = useUrlsToImages(
    {
      receiptImageIds: batchImageIds,
      useReceiptImages: useReceiptImages,
    }
  );

  const imageUrlToTypeMap = useFileUrlToTypeMapping(receiptImageUrls);

  return (
    <EnlargeImageGallery
      loading={ImageUrlsLoading}
      open={openImageGallery}
      handleClose={() => setOpenImageGallery(false)}
      initialSelectedIndex={0}
      imageUrls={
        generatedReceiptImageUrls.length > 0
          ? generatedReceiptImageUrls
          : receiptImageUrls || []
      }
      imageUrlToTypeMap={
        generatedReceiptImageUrls.length > 0
          ? generatedReceiptTypeMap
          : imageUrlToTypeMap
      }
      title={title}
    />
  );
};
