import {
  useResident,
  useResidents,
} from '@pflegenavi/frontend/api-nursing-home';
import { useMemo } from 'react';
import type { SelectResidentProps } from './SelectResident';
import { SelectResident } from './SelectResident';
import type { Gender } from '@pflegenavi/shared/api';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';

interface Props extends Omit<SelectResidentProps, 'residents'> {
  autoFocus?: boolean;
  residentId?: string;
  setResidentId: (resident?: string) => void;
  exclude?: string[];
  useResidentsHook?: typeof useResidents;
}

export const SelectAllResidents: React.FC<Props> = ({
  useResidentsHook = useResidents,
  ...props
}) => {
  const { selectedNursingHome } = useNursingHomeContext();

  // react query call
  const { data: residents } = useResidentsHook(selectedNursingHome?.id, {
    refetchOnWindowFocus: false,
  });

  const { data: selectedResident } = useResident(props.residentId ?? '', {
    enabled: props.residentId !== undefined,
  });

  return (
    <SelectAllResidentsView
      selectedResident={selectedResident}
      residents={residents}
      {...props}
    />
  );
};

interface SelectAllResidentsViewProps
  extends Omit<Props, 'useResidentsHook' | 'residentId'> {
  residents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  selectedResident?: {
    id: string;
    name: string;
    gender: Gender;
  };
  minWidth?: number;
  maxWidth?: number;
}

export const SelectAllResidentsView: React.FC<SelectAllResidentsViewProps> = ({
  exclude,
  residents,
  selectedResident,
  ...props
}) => {
  const residentsRelevantFields = useMemo(() => {
    let base =
      (exclude
        ? residents?.filter((resident) => !exclude.includes(resident.id))
        : residents) ?? [];
    if (selectedResident) {
      if (!base.find((resident) => selectedResident.id === resident.id)) {
        base = base.concat(selectedResident);
      }
    }
    return base.map((resident) => {
      return {
        id: resident.id,
        label: resident.name,
        value: resident.id,
        gender: resident.gender,
      };
    });
  }, [exclude, residents, selectedResident]);

  return (
    <SelectResident
      residents={residentsRelevantFields}
      residentId={selectedResident?.id}
      {...props}
    />
  );
};
