import type { FC } from 'react';
import React from 'react';
import {
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
  Typography,
} from '@mui/material';
import type { MangopayPaymentResolution } from '@pflegenavi/service-provider-frontend/api';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';

interface MangopaySingleReceiptAmountLabelTooltipProps
  extends Omit<TooltipProps, 'title'> {
  reason: string;
  paymentResolution?: MangopayPaymentResolution;
}

export const MangopaySingleReceiptAmountLabelTooltip: FC<
  MangopaySingleReceiptAmountLabelTooltipProps
> = ({ reason, paymentResolution, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <CustomTooltip
      {...props}
      title={
        <Stack gap={0.5} px={0.5} py={0.5}>
          <Typography color="inherit" variant="subtitle1">
            {t('receipts.batch.payment.pending-payment')}
          </Typography>
          <Stack>
            <Typography color="inherit" variant="subtitle2">
              {mapReason(t, reason)}
            </Typography>
            {paymentResolution && (
              <Typography variant="subtitle2">
                {t('receipts.batch.resolution-date')}:{' '}
                {mapResolutionDate(t, paymentResolution.resolution_date)}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
      placement="bottom"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <div>{children}</div>
    </CustomTooltip>
  );
};

function mapReason(t: TFunction, reason: string) {
  if (reason === 'low_balance') {
    return t('receipts.batch.reason-low-balance');
  }

  return t('receipts.batch.reason-unknown');
}

function mapResolutionDate(t: TFunction, date: Date | undefined | 'unknown') {
  if (date === 'unknown') {
    return t('receipts.batch.resolution-date-unknown');
  }
  return date ? new Date(date).toDateString() : '';
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.lighter,
    color: theme.palette.warning.darker,
    minWidth: 500,
  },
}));
