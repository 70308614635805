import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';
import type { ITracking } from './ITracking';
import type { StructuredEvents } from './Event';

const emptyTracker: ITracking = {
  trackStructuredEvent(): Promise<void> {
    throw new Error('Tracker not initialized');
  },
  initialize() {
    return;
  },
};

export const TrackingContext = createContext<ITracking>(emptyTracker);

interface TrackingProviderProps {
  tracker: ITracking;
}

export const TrackingProvider: FC<PropsWithChildren<TrackingProviderProps>> = ({
  tracker,
  children,
}) => {
  return (
    <TrackingContext.Provider value={tracker}>
      {children}
    </TrackingContext.Provider>
  );
};

const useTracking = (): ITracking => {
  return useContext(TrackingContext);
};

export type UseTrackStructuredEvent = typeof useTrackStructuredEvent;
export type TrackEvent<Event extends keyof StructuredEvents> = (
  event: Omit<StructuredEvents[Event], 'category'>
) => Promise<void>;

export function useTrackStructuredEvent<Event extends keyof StructuredEvents>(
  events: Event
): (event: Omit<StructuredEvents[Event], 'category'>) => Promise<void> {
  const tracking = useTracking();
  return useCallback(
    (event) => {
      return tracking.trackStructuredEvent({
        ...event,
        category: events,
      });
    },
    [events, tracking]
  );
}
