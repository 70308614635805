import type { FC } from 'react';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';

interface ResidentTransactionInfoColumnProps {
  info: string;
  serviceProvider?: string;
}

export const ResidentTransactionInfoColumn: FC<
  ResidentTransactionInfoColumnProps
> = ({ info, serviceProvider }) => {
  const ellipsisStyles = useMemo(
    () => ({
      width: 150,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    []
  );

  const serviceProviderRef = useRef<HTMLParagraphElement>(null);
  const infoRef = useRef<HTMLParagraphElement>(null);

  const [showServiceProviderTooltip, setShowServiceProviderTooltip] =
    useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  useLayoutEffect(() => {
    if (
      serviceProviderRef.current &&
      serviceProviderRef.current.offsetWidth <
        serviceProviderRef.current.scrollWidth
    ) {
      setShowServiceProviderTooltip(true);
    }
    if (
      infoRef.current &&
      infoRef.current.offsetWidth < infoRef.current.scrollWidth
    ) {
      setShowInfoTooltip(true);
    }
  }, []);

  if (serviceProvider) {
    return (
      <Tooltip
        arrow
        placement="top-start"
        title={
          showServiceProviderTooltip || showInfoTooltip
            ? `${info} - ${serviceProvider}`
            : undefined
        }
      >
        <div>
          <Typography
            ref={serviceProviderRef}
            variant="subtitle2"
            sx={ellipsisStyles}
          >
            {serviceProvider}
          </Typography>
          <Typography
            ref={infoRef}
            variant="subtitle2"
            color="text.secondary"
            sx={ellipsisStyles}
          >
            {info}
          </Typography>
        </div>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      arrow
      placement="top-start"
      title={showInfoTooltip ? info : undefined}
    >
      <Typography ref={infoRef} variant="subtitle2" sx={ellipsisStyles}>
        {info}
      </Typography>
    </Tooltip>
  );
};
