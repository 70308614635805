import type { FC } from 'react';
import React, { memo, useCallback } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Label } from '@pflegenavi/web-components';
import { SelectResidentRow } from './QuickSelectResidentRow';
import type { ResidentType } from './ReceiptBatchQuickResidentSelectionForm';
import { useTranslation } from 'react-i18next';
import { useTrackStructuredEvent } from '@pflegenavi/frontend/tracking';

interface NonSelectedResidentsProps {
  filteredNonSelected: ResidentType[];
  staged: ResidentType[];
  handleStageResident: (resident: ResidentType) => void;
  handleUnStageResident: (resident: ResidentType) => void;
}

export const NonSelectedResidents: FC<NonSelectedResidentsProps> = memo(
  ({
    filteredNonSelected,
    staged,
    handleStageResident,
    handleUnStageResident,
  }) => {
    const handleRowClick = useCallback(
      (resident: ResidentType, checked: boolean) => {
        if (checked) {
          handleStageResident(resident);
        } else {
          handleUnStageResident(resident);
        }
      },
      [handleStageResident, handleUnStageResident]
    );
    return (
      <Stack gap={1}>
        <ScrollableResidentsList>
          {filteredNonSelected.map((resident) => (
            <SelectResidentRow
              key={resident.id}
              residentId={resident.id}
              residentName={resident.name}
              residentFirstName={resident.firstName}
              residentLastName={resident.lastName}
              checked={staged.some(
                (stagedResident) => stagedResident.id === resident.id
              )}
              onClick={handleRowClick}
            />
          ))}
        </ScrollableResidentsList>
      </Stack>
    );
  }
);

interface SelectedResidentsProps {
  filteredSelected: ResidentType[];
  selected: ResidentType[];
  stagedForDeselection: ResidentType[];
  handleStageForDeselection: (resident: ResidentType) => void;
  handleUnStageForDeselection: (resident: ResidentType) => void;
}

export const SelectedResidentsList: FC<SelectedResidentsProps> = memo(
  ({
    selected,
    filteredSelected,
    stagedForDeselection,
    handleStageForDeselection,
    handleUnStageForDeselection,
  }) => {
    const handleRowClick = useCallback(
      (resident: ResidentType, checked: boolean) => {
        if (checked) {
          handleStageForDeselection(resident);
        } else {
          handleUnStageForDeselection(resident);
        }
      },
      [handleStageForDeselection, handleUnStageForDeselection]
    );
    const { t } = useTranslation();
    return (
      <>
        <Stack>
          <SelectedResidentsCounterHeader
            title={t(
              'receipts.batch.form.resident-quick-selection.selected-residents'
            )}
            count={selected.length}
          />
          <Typography variant="caption" color="grey.500">
            {t(
              'receipts.batch.form.resident-quick-selection.selected-residents-description'
            )}
          </Typography>
        </Stack>

        <ScrollableResidentsList height="43vh">
          {filteredSelected.map((resident) => (
            <SelectResidentRow
              key={resident.id}
              residentId={resident.id}
              residentName={resident.name}
              residentFirstName={resident.firstName}
              residentLastName={resident.lastName}
              checked={stagedForDeselection.some(
                (stagedResident) => stagedResident.id === resident.id
              )}
              onClick={handleRowClick}
            />
          ))}
        </ScrollableResidentsList>
      </>
    );
  }
);

interface SelectedResidentsCounterHeaderProps {
  title: string;
  count: number;
}

export const SelectedResidentsCounterHeader: FC<
  SelectedResidentsCounterHeaderProps
> = ({ title, count }) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography variant="body1" fontWeight={700}>
        {title}
      </Typography>
      <Label color={count > 0 ? 'info' : 'default'}>
        {t(
          'receipts.batch.form.resident-quick-selection.selected-residents-count-label',
          {
            count: count,
          }
        )}
      </Label>
    </Stack>
  );
};

export interface SelectAllResidentsCheckboxProps {
  checkboxKey: string;
  disabled: boolean;
  handleStageAllNonSelected: () => void;
  handleUnStageAll: () => void;
}

export const SelectAllResidentsCheckbox: FC<SelectAllResidentsCheckboxProps> =
  memo(
    ({
      checkboxKey,
      disabled,
      handleStageAllNonSelected,
      handleUnStageAll,
    }) => {
      const { t } = useTranslation();
      const trackEvent = useTrackStructuredEvent('ResidentQuickSelection');
      return (
        <FormControlLabel
          sx={{
            width: 'fit-content',
            '& .MuiTypography-body1': {
              mt: '1px',
            },
          }}
          disabled={disabled}
          control={
            <Checkbox
              key={checkboxKey}
              onChange={(e, checked) => {
                checked ? handleStageAllNonSelected() : handleUnStageAll();
                void trackEvent({
                  action: 'click',
                  label: 'selectAllResidentsButton',
                });
              }}
              sx={{
                '&.Mui-checked': {
                  color: 'info.main',
                  '& .Mui-disabled': {
                    color: 'error.main',
                  },
                },
              }}
            />
          }
          label={t(
            'receipts.batch.form.resident-quick-selection.select-all-residents'
          )}
          labelPlacement="start"
        />
      );
    }
  );

interface ScrollableResidentsListProps {
  height?: string;
}

export const ScrollableResidentsList = styled(
  Stack
)<ScrollableResidentsListProps>(({ height = '42vh' }) => ({
  pt: 10,
  border: '1px solid #E0E0E0',
  borderRadius: 8,
  padding: 5,
  paddingLeft: 15,
  paddingRight: 15,
  overflowY: 'scroll',
  height: height,
  minHeight: height,
  maxHeight: height,
  position: 'relative',
  '&::-webkit-scrollbar': {
    width: '0.3em',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0,0,0,.1)',
  },
}));
