import type { FeatureFlag } from '@pflegenavi/shared/api';
import { FeatureFlagStatus } from '@pflegenavi/shared/api';
import {
  useUserProfileApi,
  useUserProfileWithContext,
} from '@pflegenavi/frontend/api-nursing-home';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useAuthenticationInternal } from '@pflegenavi/frontend/authentication';

export const useFeatureFlagBase = (
  featureFlag: FeatureFlag
): FeatureFlagStatus => {
  const api = useUserProfileApi(true);

  const { data: userProfile } = useUserProfileWithContext({
    enabled: api !== undefined,
    refetchOnWindowFocus: false,
  });
  const auth = useAuthenticationInternal();

  const { selectedNursingHome } = useNursingHomeContext();

  if (api && userProfile?.featureFlags?.includes(featureFlag)) {
    return FeatureFlagStatus.Enabled;
  }

  if (auth.user?.attributes?.[`ff.${featureFlag}`]) {
    return FeatureFlagStatus.Enabled;
  }

  if (selectedNursingHome?.featureFlags?.includes(featureFlag)) {
    return FeatureFlagStatus.Enabled;
  }

  return FeatureFlagStatus.Disabled;
};
