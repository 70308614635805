import type { FC } from 'react';
import React, { memo } from 'react';
import { Stack, Typography } from '@mui/material';
import { MoneyInput } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

interface ReceiptAmountInputProps {
  value: number | undefined;
  handleSetReceiptAmount: (amount: number) => void;
  handleResetReceiptAmount: () => void;
}

export const ReceiptAmountInput: FC<ReceiptAmountInputProps> = memo(
  ({ value, handleSetReceiptAmount, handleResetReceiptAmount }) => {
    const { t } = useTranslation();
    return (
      <Stack alignItems="flex-start" gap={1}>
        <Typography variant="body1" fontWeight={700}>
          {t(
            'receipts.batch.form.resident-quick-selection.pre-selected-receipt-amount'
          )}
        </Typography>
        <MoneyInput
          label={t('receipts.batch.form.resident-quick-selection.amount')}
          placeholder={t('receipts.batch.form.resident-quick-selection.amount')}
          value={value}
          onChange={(newAmount) => {
            if (newAmount) {
              handleSetReceiptAmount(newAmount);
            } else {
              handleResetReceiptAmount();
            }
          }}
        />
        <Typography variant="caption" color="grey.500">
          {t(
            'receipts.batch.form.resident-quick-selection.receipt-amount-description'
          )}
        </Typography>
      </Stack>
    );
  }
);
