import type { FC, PropsWithChildren } from 'react';
import React, { useMemo, useState } from 'react';
import type { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { FeatureFlagDevContext } from './FeatureFlagDevContext';

export const FeatureFlagDevProvider: FC<PropsWithChildren> = ({ children }) => {
  const [featureFlagStatus, setFeatureFlagStatus] = useState<
    Map<FeatureFlag, FeatureFlagStatus>
  >(new Map());

  const value = useMemo(
    () => ({
      data: featureFlagStatus,
      set: setFeatureFlagStatus,
    }),
    [featureFlagStatus]
  );

  return (
    <FeatureFlagDevContext.Provider value={value}>
      {children}
    </FeatureFlagDevContext.Provider>
  );
};
