import type { FC } from 'react';
import React from 'react';
import { IconButton, Stack, styled } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';

interface SelectionArrowButtonsProps {
  disabledRight: boolean;
  disabledLeft: boolean;
  onClickRight: () => void;
  onClickLeft: () => void;
}

export const SelectionArrowButtons: FC<SelectionArrowButtonsProps> = ({
  disabledRight,
  disabledLeft,
  onClickRight,
  onClickLeft,
}) => {
  return (
    <Stack
      gap={3}
      justifyContent="center"
      alignItems="center"
      sx={{ width: 40 }}
    >
      <ArrowIconButton onClick={onClickRight} disabled={disabledRight}>
        <Iconify
          icon={'eva:arrow-ios-forward-fill'}
          color="white"
          fontSize={22}
        />
      </ArrowIconButton>
      <ArrowIconButton onClick={onClickLeft} disabled={disabledLeft}>
        <Iconify icon={'eva:arrow-ios-back-fill'} color="white" fontSize={22} />
      </ArrowIconButton>
    </Stack>
  );
};

export const ArrowIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  width: 60,
  height: 30,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 5,
  '&.Mui-disabled': {
    backgroundColor: theme.palette.grey['400'],
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9,
  },
}));
