import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface RedirectUrlContextProps {
  redirectUrl: string;
  setRedirectUrl: React.Dispatch<React.SetStateAction<string>>;
}

const RedirectUrlContext = createContext<RedirectUrlContextProps | undefined>(
  undefined
);

export const useRedirectUrlContext = (): RedirectUrlContextProps => {
  const context = useContext(RedirectUrlContext);
  if (!context) {
    throw new Error('Missing RedirectUrlProvider!');
  }
  return context;
};

export const RedirectUrlProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { pathname } = useLocation();

  const [redirectUrl, setRedirectUrl] = useState<string>(pathname);

  useEffect(() => {
    const uuidPattern = /[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}/i;
    if (!pathname.match(uuidPattern)) {
      setRedirectUrl(pathname);
    }
  }, [pathname]);

  return (
    <RedirectUrlContext.Provider value={{ redirectUrl, setRedirectUrl }}>
      {children}
    </RedirectUrlContext.Provider>
  );
};
