import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdatePasswordView } from './components/UpdatePasswordView';
import { useSnackbar } from 'notistack';

export const ResetPasswordConfirm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const { token } = params;
  const auth = useAuthentication(shouldUseNewLogin());

  const [error, setError] = useState<string | undefined>(undefined);

  const onResetPasswordConfirm = useCallback(
    async (password: string, passwordConfirmation: string) => {
      if (!token) {
        setError(t('authentication.errors.invalid-token'));
        return;
      }
      try {
        const result = await auth?.resetPasswordConfirm(token, {
          password,
          passwordConfirmation,
        });

        if (result?.ok) {
          await auth?.logout({});
          navigate('/');
          enqueueSnackbar(t('authentication.password-updated-success'), {
            variant: 'success',
          });
        } else {
          setError(t('authentication.errors.invalid-token'));
        }
      } catch {
        setError(t('authentication.errors.something-went-wrong'));
      }
    },
    [token, t, auth, enqueueSnackbar, navigate]
  );

  return (
    <UpdatePasswordView
      error={error}
      onUpdatePassword={onResetPasswordConfirm}
    />
  );
};
