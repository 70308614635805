import type { FC, ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import type {
  BatchReceiptDetails,
  ReceiptBatchFormValues,
} from './ReceiptBatchForm';
import { Button, Card, Divider, Stack, Typography } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import type { Gender } from '@pflegenavi/shared/api';

export interface ReceiptBatchRenderRowProps {
  receipt: BatchReceiptDetails;
  index: number;
  removeRow: (index: number) => void;
  setShownImageId: (imgId: string | undefined) => void;
  setPreviewImageIds: (imgIds: string[]) => void;
  lastInputRef?: React.RefObject<HTMLInputElement>;
  archivedResidents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  handleEntryRecurringItemCalculation?: (
    entryId: string,
    index: number,
    amount: number
  ) => void;
}

interface ReceiptBatchReceiptsProps {
  renderRow: (props: ReceiptBatchRenderRowProps) => ReactNode;
  setShownImageId: (imgId: string | undefined) => void;
  setPreviewImageIds: (imgIds: string[]) => void;
  ReceiptBatchQuickResidentSelection?: React.ReactElement | null;
  archivedResidents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  handleEntryRecurringItemCalculation?: (
    entryId: string,
    index: number,
    amount: number
  ) => void;
}

export const createNewReceipt = (): BatchReceiptDetails => ({
  id: `new-${uuid()}`,
  residentId: undefined,
  amount: undefined,
  notes: '',
  receiptImageIds: [],
  residentActive: true,
  individualDate: undefined,
});

export const ReceiptBatchReceiptsView: FC<ReceiptBatchReceiptsProps> = ({
  renderRow,
  setShownImageId,
  setPreviewImageIds,
  ReceiptBatchQuickResidentSelection,
  archivedResidents,
  handleEntryRecurringItemCalculation,
}) => {
  const { t } = useTranslation();
  const {
    values: { receipts },
    setFieldValue,
  } = useFormikContext<ReceiptBatchFormValues>();

  const lastInputRef = useRef(null);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (
        event.key === 'Tab' &&
        !event.shiftKey &&
        event.target === lastInputRef.current
      ) {
        event.preventDefault();

        setFieldValue('receipts', [...receipts, createNewReceipt()]);
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [lastInputRef, receipts, setFieldValue]);

  return (
    <Card sx={{ p: 2 }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ mb: ReceiptBatchQuickResidentSelection !== null ? 3 : 2 }}
      >
        <Typography variant="h6" fontWeight={700}>
          {t('receipts.batch.form.details')}
        </Typography>
        {ReceiptBatchQuickResidentSelection}
      </Stack>
      <Divider
        sx={{
          width: '100%',
          mb: 4,
        }}
      />

      <FieldArray name="receipts">
        {({ remove, push }) => (
          <div>
            {receipts.map((receipt, index) => {
              return renderRow({
                index,
                receipt,
                removeRow: remove,
                setShownImageId,
                setPreviewImageIds,
                archivedResidents,
                lastInputRef:
                  index === receipts.length - 1 ? lastInputRef : undefined,
                handleEntryRecurringItemCalculation,
              });
            })}
            <Button
              variant="text"
              size="small"
              onClick={() => push(createNewReceipt())}
              startIcon={<Iconify icon={'ic:baseline-plus'} />}
            >
              {t('receipts.batch.form.add-receipt')}
            </Button>
          </div>
        )}
      </FieldArray>
    </Card>
  );
};
