import type { FC } from 'react';
import React, { useState } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import {
  Button,
  DeleteDialog,
  LoadingPromiseButtonAdvanced,
  Modal,
  ModalInner,
  usePrompt,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import type { FormikProps } from 'formik';
import { useFormikContext } from 'formik';
import type { ReceiptBatchFormValues } from './ReceiptBatchForm';

interface ReceiptBatchActionsProps {
  blockSubmit: boolean;
  allowNavigateAway: boolean;
  handleDeleteBatch?: VoidFunction;
  unblockRef?: React.MutableRefObject<() => void>;
}

export const ReceiptBatchActionButtons: FC<ReceiptBatchActionsProps> = ({
  blockSubmit,
  allowNavigateAway,
  handleDeleteBatch,
  unblockRef,
}) => {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const formikContext = useFormikContext<ReceiptBatchFormValues>();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const unblock = usePrompt(
    t('confirm-page-leave'),
    allowNavigateAway ? false : formikContext.dirty
  );
  if (unblockRef) {
    unblockRef.current = unblock.unblock;
  }

  return (
    <ReceiptBatchActionButtonsUI
      deleteModalOpen={deleteModalOpen}
      setDeleteModalOpen={setDeleteModalOpen}
      cancelModalOpen={cancelModalOpen}
      setCancelModalOpen={setCancelModalOpen}
      formikContext={formikContext}
      blockSubmit={blockSubmit}
      handleDeleteBatch={handleDeleteBatch}
    />
  );
};

type MinimalFormikContext = Pick<
  FormikProps<ReceiptBatchFormValues>,
  'dirty' | 'submitForm' | 'isSubmitting' | 'isValid' | 'resetForm'
>;

export interface ReceiptBatchActionsUIProps {
  deleteModalOpen: boolean;
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cancelModalOpen: boolean;
  setCancelModalOpen: (open: boolean) => void;
  formikContext: MinimalFormikContext;
  blockSubmit: boolean;
  handleDeleteBatch?: VoidFunction;
}

// eslint-disable-next-line complexity
export const ReceiptBatchActionButtonsUI: FC<ReceiptBatchActionsUIProps> = ({
  deleteModalOpen,
  setDeleteModalOpen,
  cancelModalOpen,
  setCancelModalOpen,
  formikContext,
  blockSubmit,
  handleDeleteBatch,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      justifyContent={handleDeleteBatch ? 'space-between' : 'flex-end'}
      spacing={1}
      sx={{ mt: 3 }}
    >
      {handleDeleteBatch && (
        <>
          <Button
            onClick={() => setDeleteModalOpen(true)}
            color="error"
            variant="text"
          >
            {t('receipts.batch.form.delete-batch')}
          </Button>
          <DeleteDialog
            open={deleteModalOpen}
            handleDelete={handleDeleteBatch}
            setDeleteDialogOpen={setDeleteModalOpen}
            title={t('receipts.batch.form.delete-batch')}
            confirmationMessage={t('receipts.batch.form.confirm-delete-text')}
          />
        </>
      )}
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        {formikContext.dirty && (
          <>
            <Button variant="outlined" onClick={() => setCancelModalOpen(true)}>
              {t('receipts.batch.form.abort-changes')}
            </Button>
            <ConfirmCancelModal
              open={cancelModalOpen}
              setOpen={setCancelModalOpen}
              formikContext={formikContext}
            />
          </>
        )}

        <LoadingPromiseButtonAdvanced
          name={'submit-form-button'}
          disabled={
            formikContext.isSubmitting || !formikContext.dirty || blockSubmit
          }
          variant="contained"
          onClick={async () => await formikContext.submitForm()}
        >
          {t('actions.save')}
        </LoadingPromiseButtonAdvanced>
      </Stack>
    </Stack>
  );
};

interface ConfirmCancelModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  formikContext: MinimalFormikContext;
}

export const ConfirmCancelModal: FC<ConfirmCancelModalProps> = ({
  open,
  setOpen,
  formikContext,
}) => {
  const { t } = useTranslation();

  const handleConfirmCancel = () => {
    formikContext.resetForm();
    setOpen(false);
  };
  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <ModalInner>
        <Box sx={{ width: 450 }}>
          <Card sx={{ p: 3 }}>
            <Typography sx={{ mb: 2 }}>
              {t('receipts.batch.form.abort-changes-confirmation-text')}
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                {t('receipts.batch.form.abort-changes-no')}
              </Button>

              <Button
                color="error"
                variant="contained"
                onClick={handleConfirmCancel}
              >
                {t('receipts.batch.form.abort-changes-yes')}
              </Button>
            </Stack>
          </Card>
        </Box>
      </ModalInner>
    </Modal>
  );
};
