import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  AuthenticationError,
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import { Button, Stack, Typography } from '@mui/material';
import PflegenaviLogo from './assets/pflegenavi_logo.svg';
import { useTranslation } from 'react-i18next';
import { PageContainer } from './components/PageContainer';
import { useNavigate } from 'react-router-dom';

enum Application {
  NursingHome = 'nursing-home',
  ServiceProvider = 'service-provider',
  FamilyMember = 'family-member',
}

export const Welcome: FC = (): JSX.Element => {
  const auth = useAuthentication(shouldUseNewLogin());

  // This will not work properly locally, as there will not be subdomains for FM and SP apps
  const application: Application = useMemo(() => {
    const url = new URL(window.location.href);
    const hostnames = url.hostname.split('.');
    if (
      hostnames[0] === Application.FamilyMember ||
      hostnames[0] === Application.ServiceProvider
    ) {
      return hostnames[0] as Application;
    } else {
      return Application.NursingHome;
    }
  }, []);

  if (auth?.error) {
    if (
      auth.error === AuthenticationError.InvitationExpired &&
      auth.errorOptions?.invitationWasValidFor
    ) {
      return (
        <WelcomeLinkExpired
          invitationWasValidFor={auth.errorOptions.invitationWasValidFor}
          application={application}
        />
      );
    }
    return <Error />;
  }
  // Usually we don't render anything here
  return <span>Welcome!</span>;
};

interface WelcomeLinkExpiredProps {
  invitationWasValidFor: string;
  application: Application;
}

const WelcomeLinkExpired: FC<WelcomeLinkExpiredProps> = ({
  invitationWasValidFor,
  application,
}) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <Typography variant="h4" mb={-1}>
          {t('authentication.errors.invitation-expired-title')}
        </Typography>
        <Typography variant="h6" textAlign="center">
          {application === Application.NursingHome
            ? t(
                'authentication.errors.invitation-expired-description-generic',
                {
                  days: invitationWasValidFor,
                }
              )
            : t('authentication.errors.invitation-expired-description', {
                days: invitationWasValidFor,
              })}
        </Typography>
      </Stack>
    </PageContainer>
  );
};

const Error: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageContainer>
      <Stack gap={3} width={550} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <Typography variant="h4" mb={-1}>
          {t('authentication.errors.something-went-wrong-title')}
        </Typography>
        <Typography variant="h6" textAlign="center">
          {t('authentication.errors.something-went-wrong-description')}
        </Typography>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('authentication.back-to-login')}
        </Button>
      </Stack>
    </PageContainer>
  );
};
