import type { SelectProps } from '@pflegenavi/web-components';
import { Stack } from '@pflegenavi/web-components';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, createFilterOptions, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';

export interface TransactionTypesFieldProps extends SelectProps {
  disabled?: boolean;
  receiptTypes: Array<{
    value: string;
    label: string;
  }>;
  receiptType?: string;
  onChangeReceiptType: (value: string) => void;
  errorMessage?: string;
}

export const TransactionTypesFieldBase = ({
  receiptType,
  onChangeReceiptType,
  receiptTypes: transactionTypes,
  disabled,
  errorMessage,
  name,
}: TransactionTypesFieldProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedTransactionType, setSelectedTransactionType] = useState<
    string | null
  >(
    () =>
      transactionTypes.find(
        (transactionType) => transactionType.value === receiptType
      )?.value ?? null
  );
  useEffect(() => {
    const newSelectedTransactionType =
      transactionTypes.find(
        (transactionType) => transactionType.value === receiptType
      ) ?? null;
    if (newSelectedTransactionType?.value !== selectedTransactionType) {
      setSelectedTransactionType(newSelectedTransactionType?.value ?? null);
    }
    // selectedTransactionType missing to avoid circular dependency
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, [receiptType, transactionTypes]);

  const selected = useMemo(() => {
    if (!selectedTransactionType) {
      return null;
    }

    const selectedLabel = transactionTypes.find(
      (type) => type.value === selectedTransactionType
    )?.label;

    return {
      value: selectedTransactionType,
      label: selectedLabel,
    };
  }, [transactionTypes, selectedTransactionType]);

  const defaultInputValue = selected?.label ?? '';
  const [inputValue, setInputValue] = useState(defaultInputValue);
  useEffect(() => {
    setInputValue(defaultInputValue);
  }, [setInputValue, defaultInputValue]);

  const value = useMemo(() => {
    return selected ?? { value: '', label: '' };
  }, [selected]);

  return (
    <Autocomplete
      data-cy="transaction-type"
      disabled={disabled}
      openOnFocus={true}
      value={value}
      onChange={(_, value) => {
        onChangeReceiptType(value.value);
      }}
      filterOptions={createFilterOptions({ limit: 7 })}
      ListboxProps={{
        style: {
          maxHeight: '354px',
        },
      }}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      disableClearable={true}
      renderInput={({ ...params }) => (
        <TextField
          name={name}
          label={t('receipts.select-type')}
          type={'text'}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          {...params}
        />
      )}
      options={transactionTypes ?? []}
    />
  );
};

interface TransactionTypeChipProps {
  selected: boolean;
  value: string;
  label: string;
  setValue: (value: string) => void;
}

const TransactionTypeChip: FC<TransactionTypeChipProps> = ({
  value,
  label,
  setValue,
  selected,
}) => {
  const onClick = useCallback(() => {
    setValue(value);
  }, [value, setValue]);
  return (
    <Chip
      tabIndex={-1}
      variant="filled"
      color={selected ? 'primary' : 'default'}
      label={label}
      onClick={onClick}
      className={'transactionTypeChip'}
    />
  );
};

export const TransactionTypesField = memo(
  ({
    receiptType,
    onChangeReceiptType,
    disabled,
    receiptTypes,
    ...props
  }: TransactionTypesFieldProps): JSX.Element => {
    return (
      <Stack direction="column" gap={1} flex={1}>
        <TransactionTypesFieldBase
          disabled={disabled}
          receiptTypes={receiptTypes}
          onChangeReceiptType={onChangeReceiptType}
          receiptType={receiptType}
          {...props}
        />
        {!disabled && (
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {receiptTypes.map((transactionType) => (
              <TransactionTypeChip
                key={transactionType.value}
                value={transactionType.value}
                selected={transactionType.value === receiptType}
                label={transactionType.label}
                setValue={onChangeReceiptType}
              />
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);
