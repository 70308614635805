import type { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { useContext } from 'react';
import { FeatureFlagDevContext } from './FeatureFlagDevContext';
import { useFeatureFlagBase } from './useFeatureFlagBase';

/**
 * Return the feature flag including the ones that are currently set in the dev tools
 * @param featureFlag
 */
export const useFeatureFlagDev = (
  featureFlag: FeatureFlag
): FeatureFlagStatus => {
  const { data } = useContext(FeatureFlagDevContext);

  const status = data.get(featureFlag);

  const backendFeatureFlag = useFeatureFlagBase(featureFlag);

  if (status === undefined) {
    return backendFeatureFlag;
  }

  return status;
};
