import type { FC, PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { Container, Stack, styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { LanguagePicker } from './LanguagePicker';
import { Footer } from './Footer';
import {
  getPflegenaviAppName,
  PflegenaviAppName,
} from '@pflegenavi/shared/utils';
import { SupportEmail } from '@pflegenavi/shared/constants';

interface PageContainerProps extends PropsWithChildren {
  gapBetweenChildren?: number;
}

export const PageContainer: FC<PageContainerProps> = ({
  children,
  gapBetweenChildren = 14,
}) => {
  const supportEmails: Record<PflegenaviAppName, SupportEmail> = useMemo(() => {
    return {
      [PflegenaviAppName.NURSING_HOME]: SupportEmail.NURSING_HOME,
      [PflegenaviAppName.FAMILY_MEMBER]: SupportEmail.FAMILY_MEMBER,
      [PflegenaviAppName.SERVICE_PROVIDER]: SupportEmail.SERVICE_PROVIDER,
    };
  }, []);

  return (
    <ContainerStyle maxWidth={false}>
      <Stack gap={gapBetweenChildren}>
        <Stack alignItems="flex-end">
          <LanguagePicker />
        </Stack>
        <Stack justifyContent="center" alignItems="center" mt={-5}>
          {children}
        </Stack>
      </Stack>
      <Footer supportEmail={supportEmails[getPflegenaviAppName()]} />
    </ContainerStyle>
  );
};

const ContainerStyle = styled(Container)(
  sx({
    bgcolor: 'secondary.lighter',
    width: '100vw',
    minHeight: '100vh',
    overflowY: 'auto',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);
