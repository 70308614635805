import type { FC } from 'react';
import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Iconify,
  LoadingPromiseButtonAdvanced,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

import PflegenaviLogo from '../assets/pflegenavi_logo.svg';
import { usePasswordValidation } from '@pflegenavi/shared/frontend';
import { PasswordField } from './PasswordField';
import { PageContainer } from './PageContainer';

interface UpdatePasswordViewProps {
  onUpdatePassword: (
    password: string,
    passwordConfirmation: string
  ) => Promise<void>;
  error?: string;
}

export const UpdatePasswordView: FC<UpdatePasswordViewProps> = ({
  onUpdatePassword,
  error,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const { criteria } = usePasswordValidation(password, passwordConfirmation);

  return (
    <PageContainer gapBetweenChildren={8}>
      <Stack gap={3} width={600} alignItems="center">
        <img src={PflegenaviLogo} width={360} />
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onUpdatePassword(password, passwordConfirmation);
          }}
          style={{ width: '100%' }}
        >
          <Stack gap={2} width="100%" alignItems="center">
            <Typography variant="h4" mb={-1}>
              {t('authentication.reset-password')}
            </Typography>
            <Stack gap={1} width="100%">
              <Stack>
                <Typography mb={-1}>
                  {t('authentication.reset-password-password')}:
                </Typography>
                <List>
                  {criteria.map(([message, valid]) => (
                    <ListItem
                      key={message}
                      sx={{
                        padding: 0,
                        mt: 0.3,
                      }}
                    >
                      <ListItemIcon sx={{ mr: 1 }}>
                        {valid ? (
                          <Iconify
                            icon="fa-solid:check"
                            color={theme.palette.success.main}
                            fontSize={18}
                          />
                        ) : (
                          <Iconify
                            icon="vaadin:close"
                            color={theme.palette.error.main}
                            fontSize={18}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                        }}
                      >
                        {message}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Stack>
              {error && (
                <Typography variant="body1" color="error">
                  {error}
                </Typography>
              )}
              <PasswordField
                fullWidth
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value as string)}
                placeholder={t('authentication.new-password')}
              />
              <PasswordField
                fullWidth
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={(e) =>
                  setPasswordConfirmation(e.target.value as string)
                }
                placeholder={t('authentication.confirm-password')}
              />
            </Stack>
            <LoadingPromiseButtonAdvanced
              disabled={criteria.some(([, valid]) => !valid) || !!error}
              variant="contained"
              type="submit"
              onClick={async () => {
                await onUpdatePassword(password, passwordConfirmation);
              }}
              fullWidth
              sx={{
                py: 1.8,
                fontSize: 16,
              }}
            >
              {t('authentication.submit')}
            </LoadingPromiseButtonAdvanced>
          </Stack>
        </form>
      </Stack>
    </PageContainer>
  );
};
