import { type FC, useState } from 'react';
import type { TextFieldProps } from '@mui/material';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';

type PasswordFieldProps = Omit<TextFieldProps, 'type'>;

export const PasswordField: FC<PasswordFieldProps> = ({
  InputProps,
  ...props
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <Iconify
              onClick={handleClickShowPassword}
              icon={showPassword ? 'mdi:eye' : 'mdi:eye-off'}
              color={theme.palette.text.primary}
              sx={{ cursor: 'pointer', mr: 0.2 }}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
