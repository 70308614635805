import type { NursingHomeProfileDto } from '@pflegenavi/shared/api';
import { createContext, useContext } from 'react';

interface NursingHomeContextProps {
  selectedNursingHome: NursingHomeProfileDto | undefined;
  initNursingHome: (NursingHome: NursingHomeProfileDto) => Promise<void>;
  nursingHomeList: NursingHomeProfileDto[];
  setNursingHomeList: (nursingHomeList: NursingHomeProfileDto[]) => void;
}

export const NursingHomeContext = createContext<
  NursingHomeContextProps | undefined
>(undefined);

export const useNursingHomeContext = (): NursingHomeContextProps => {
  const context = useContext(NursingHomeContext);
  if (!context) {
    throw new Error('Missing NursingHomeProvider!');
  }
  return context;
};
