import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Iconify, Modal, ModalInner } from '@pflegenavi/web-components';
import {
  Alert,
  Card,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import { experimental_sx as sx } from '@mui/system';
import type { ResidentType } from './ReceiptBatchQuickResidentSelectionForm';
import { ReceiptBatchQuickResidentSelectionForm } from './ReceiptBatchQuickResidentSelectionForm';
import { useFormikContext } from 'formik';
import type { ReceiptBatchFormValues } from '../ReceiptBatchForm';
import { useTranslation } from 'react-i18next';
import type { Gender } from '@pflegenavi/shared/api';

interface ReceiptBatchQuickResidentSelectionModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showAll: boolean;
  onChangeShowAll: (showAll: boolean) => void;
  residents: Array<{
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    gender: Gender;
  }>;
}

export const ReceiptBatchQuickResidentSelectionModal: FC<
  ReceiptBatchQuickResidentSelectionModalProps
> = ({ open, setOpen, residents, showAll, onChangeShowAll }) => {
  const {
    setFieldValue,
    values: { receipts },
  } = useFormikContext<ReceiptBatchFormValues>();

  const onConfirm = useCallback(
    (residents: ResidentType[], receiptAmount?: number, date?: Date) => {
      const newAddedReceipts = residents.map((resident) => ({
        residentId: resident.id,
        individualDate: date,
        amount: receiptAmount,
      }));

      // this is relevant when the user didn't add any receipts yet, we remove the first empty row
      if (
        receipts.length === 1 &&
        receipts[0].amount === undefined &&
        receipts[0].residentId === undefined
      ) {
        setFieldValue('receipts', newAddedReceipts);
        return;
      }
      const finalReceipts = [...receipts, ...newAddedReceipts];
      setFieldValue('receipts', finalReceipts);
    },
    [receipts, setFieldValue]
  );

  return (
    <ReceiptBatchQuickResidentSelectionModalView
      key={open ? 'open' : 'closed'}
      open={open}
      setOpen={setOpen}
      residents={residents}
      onConfirm={onConfirm}
      showAll={showAll}
      onChangeShowAll={onChangeShowAll}
    />
  );
};

interface ReceiptBatchQuickResidentSelectionModalViewProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  residents: ResidentType[];
  onConfirm: (
    residents: ResidentType[],
    receiptAmount: number | undefined,
    date?: Date
  ) => void;
  showAll: boolean;
  onChangeShowAll: (showAll: boolean) => void;
}

export const ReceiptBatchQuickResidentSelectionModalView: FC<
  ReceiptBatchQuickResidentSelectionModalViewProps
> = ({ open, setOpen, residents, onConfirm, showAll, onChangeShowAll }) => {
  const { t } = useTranslation();

  const handleClose = (_: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalInner>
        <ModalCard>
          <Stack gap={3} sx={{ flex: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" fontWeight={700}>
                {t('receipts.batch.form.resident-quick-selection.title')}
              </Typography>
              <IconButton
                onClick={() => setOpen(false)}
                sx={{
                  padding: '16px',
                  marginRight: '-8px',
                }}
              >
                <Iconify
                  icon={'eva:close-fill'}
                  sx={{
                    position: 'absolute',
                  }}
                  width={20}
                />
              </IconButton>
            </Stack>
            <Alert severity="info">
              {t('receipts.batch.form.resident-quick-selection.info-alert')}
            </Alert>
            <ReceiptBatchQuickResidentSelectionForm
              setOpen={setOpen}
              residents={residents}
              onConfirm={onConfirm}
              showAll={showAll}
              onChangeShowAll={onChangeShowAll}
            />
          </Stack>
        </ModalCard>
      </ModalInner>
    </Modal>
  );
};

const ModalCard = styled(Card)(
  sx({
    p: 3,
    minWidth: 600,
    width: 1200,
    maxWidth: '100%',
    userSelect: 'none',
  })
);
