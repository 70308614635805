import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import { useNavigate } from 'react-router-dom';
import { UpdatePasswordView } from './components/UpdatePasswordView';

export const RequiredResetPassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const auth = useAuthentication(shouldUseNewLogin());

  const [error, setError] = useState<string | undefined>(undefined);

  const onResetPasswordConfirm = useCallback(
    async (password: string, passwordConfirmation: string) => {
      try {
        const result = await auth?.resetPasswordConfirm(undefined, {
          password,
          passwordConfirmation,
        });

        if (result?.ok) {
          navigate('/');
        }
      } catch {
        setError(t('authentication.errors.something-went-wrong'));
      }
    },
    [t, auth, navigate]
  );

  return (
    <UpdatePasswordView
      error={error}
      onUpdatePassword={onResetPasswordConfirm}
    />
  );
};
