import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { ReceiptBatchRenderRowProps } from './ReceiptBatchReceiptsView';
import type { FormikErrors } from 'formik';
import { useFormikContext } from 'formik';
import type {
  BatchReceiptDetails,
  ReceiptBatchFormValues,
} from './ReceiptBatchForm';
import { SelectAllResidentsView } from '@pflegenavi/frontend/resident-components';
import { ReceiptBatchRowView } from './ReceiptBatchRowView';
import { useTranslation } from 'react-i18next';
import type { Gender } from '@pflegenavi/shared/api';
import { ImageUploadDropZone } from '@pflegenavi/web-components';

interface ReceiptBatchRowContainerProps extends ReceiptBatchRenderRowProps {
  upload: (file: File) => Promise<string | undefined>;
  residents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  archivedResidents?: Array<{
    id: string;
    name: string;
    gender: Gender;
  }>;
  isLoadingResidents: boolean;
  enqueueSnackbarOnce: (message: string, options?: any) => void;
  showTabTooltip: boolean;
  setShowTabTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  autoGeneratedReceipts: boolean;
}

export const ReceiptBatchRowContainer: FC<ReceiptBatchRowContainerProps> = ({
  receipt,
  removeRow,
  index,
  setShownImageId,
  setPreviewImageIds,
  residents,
  archivedResidents,
  isLoadingResidents,
  enqueueSnackbarOnce,
  lastInputRef,
  showTabTooltip,
  setShowTabTooltip,
  upload,
  handleEntryRecurringItemCalculation,
  autoGeneratedReceipts,
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<ReceiptBatchFormValues>();

  const {
    errors: { receipts: receiptsErrors },
    touched: { receipts: receiptsTouched },
    values,
    setFieldTouched,
    setValues,
  } = formik;

  const setReceiptFieldValue = useCallback(
    (field: string, value: any) => {
      setValues((prevState) => ({
        ...prevState,
        receipts: prevState.receipts.map((receipt, i) => {
          if (i === index) {
            return {
              ...receipt,
              [field]: value,
            };
          }
          return receipt;
        }),
      }));
    },
    [index, setValues]
  );

  const errors = useMemo(() => {
    return receiptsErrors
      ? (receiptsErrors[index] as FormikErrors<BatchReceiptDetails>)
      : undefined;
  }, [index, receiptsErrors]);
  const touched = useMemo(() => {
    return receiptsTouched ? receiptsTouched[index] ?? undefined : undefined;
  }, [receiptsTouched, index]);

  const selectedResident = useMemo(() => {
    return (
      residents?.find((resident) => resident.id === receipt.residentId) ??
      archivedResidents?.find((resident) => resident.id === receipt.residentId)
    );
  }, [receipt.residentId, residents, archivedResidents]);

  const showResidentError = useMemo(() => {
    return (
      Boolean(errors?.residentId) &&
      (values.receipts[index].residentId !== undefined ||
        (values.receipts[index].residentId === undefined &&
          touched?.residentId !== undefined))
    );
  }, [errors?.residentId, values.receipts, index, touched]);

  const SelectResidents = useMemo(() => {
    if (isLoadingResidents) {
      return null;
    }

    if (receipt.residentId !== undefined && selectedResident === undefined) {
      setReceiptFieldValue('residentId', undefined);
      enqueueSnackbarOnce(t('receipts.batch.form.resident-not-found'), {
        variant: 'error',
      });
    }

    return (
      <SelectAllResidentsView
        name={`receipts.${index}.residentId`}
        residents={residents}
        selectedResident={selectedResident}
        setResidentId={(residentId) => {
          setReceiptFieldValue('residentId', residentId);
        }}
        error={showResidentError}
        helperText={showResidentError ? errors?.residentId : undefined}
        autoFocus={index > 0}
        handleBlur={() => setFieldTouched(`receipts.${index}.residentId`)}
        variant="standard"
        hideAvatar
        InputLabelProps={{ shrink: true }}
        label={t('receipts.batch.form.resident')}
      />
    );
  }, [
    isLoadingResidents,
    receipt.residentId,
    selectedResident,
    index,
    residents,
    showResidentError,
    errors?.residentId,
    t,
    enqueueSnackbarOnce,
    setReceiptFieldValue,
    setFieldTouched,
  ]);

  const handleSetImages = useCallback(
    (imageIds: string[]) => {
      // We only accept single files at the moment when using drag + drop to be consistent with the file input dialog
      if (imageIds.length !== 1) {
        return;
      }

      const receiptImageIds = values.receipts[index].receiptImageIds;
      if (receiptImageIds && receiptImageIds.length > 0) {
        setReceiptFieldValue('receiptImageIds', [
          ...receiptImageIds,
          ...imageIds,
        ]);
      } else {
        setReceiptFieldValue('receiptImageIds', imageIds);
      }
    },
    [index, setReceiptFieldValue, values.receipts]
  );

  return (
    <ImageUploadDropZone
      upload={upload}
      setImageIds={handleSetImages}
      accept={'image/jpeg,image/png,application/pdf'}
      limit={1}
    >
      <ReceiptBatchRowView
        index={index}
        receipt={receipt}
        individualDatesMode={values.individualDatesMode}
        setReceiptFieldValue={setReceiptFieldValue}
        removeRow={removeRow}
        SelectResidents={SelectResidents}
        errors={errors}
        touched={touched}
        setFieldTouched={setFieldTouched}
        setShownImageId={setShownImageId}
        setPreviewImageIds={setPreviewImageIds}
        lastInputRef={lastInputRef}
        showTabTooltip={showTabTooltip}
        setShowTabTooltip={setShowTabTooltip}
        upload={upload}
        addImages={handleSetImages}
        handleEntryRecurringItemCalculation={
          handleEntryRecurringItemCalculation
        }
        autoGeneratedReceipts={autoGeneratedReceipts}
      />
    </ImageUploadDropZone>
  );
};
