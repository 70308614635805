import type { FC } from 'react';
import React, { useCallback } from 'react';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Iconify } from '@pflegenavi/web-components';
import { useChangeLocale, useLocale } from '@pflegenavi/frontend/localization';

export const LanguagePicker: FC = () => {
  const locale = useLocale();
  const changeLocale = useChangeLocale();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = useCallback(
    (locale: 'en-GB' | 'de-AT') => {
      changeLocale(locale);
      handleClose();
    },
    [changeLocale]
  );

  return (
    <div>
      <Button
        onClick={handleClick}
        endIcon={<Iconify icon="mdi:chevron-down" />}
        sx={{
          pl: 1,
          width: 100,
        }}
      >
        {locale === 'en-GB' ? 'English' : 'Deutsch'}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <LanguageDropdownItem
          label="Deutsch"
          onClick={() => handleSelectLanguage('de-AT')}
        />
        <LanguageDropdownItem
          label="English"
          onClick={() => handleSelectLanguage('en-GB')}
        />
      </Menu>
    </div>
  );
};

interface LanguageDropdownItemProps {
  label: string;
  onClick: () => void;
}

const LanguageDropdownItem: FC<LanguageDropdownItemProps> = ({
  label,
  onClick,
}) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        width: 100,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant="subtitle2">{label}</Typography>
      </Stack>
    </MenuItem>
  );
};
