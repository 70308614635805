import { Alert } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { MangopayReceiptBatchEntry } from '@pflegenavi/service-provider-frontend/api';
import { useTranslation } from 'react-i18next';

interface ReceiptDetailPartialPaymentWarningProps {
  pendingPaymentReceiptBatchEntries: MangopayReceiptBatchEntry[];
}

export const ReceiptDetailPartialPaymentWarning: FC<
  ReceiptDetailPartialPaymentWarningProps
> = ({ pendingPaymentReceiptBatchEntries }) => {
  const { t } = useTranslation();

  const count = useMemo(() => {
    return pendingPaymentReceiptBatchEntries.length;
  }, [pendingPaymentReceiptBatchEntries.length]);

  return (
    <Alert severity="warning">
      {t('receipts.batch.payment.partial-payment-warning', { count })}
    </Alert>
  );
};
