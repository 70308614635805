import * as React from 'react';
import { useMemo } from 'react';
import type { FormikProps } from 'formik';
import { useFormikContext } from 'formik';

import type { ReceiptBatchFormValues } from './ReceiptBatchForm';
import { ReceiptBatchV2CommonView } from './ReceiptBatchV2CommonView';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import type { RecurringItemDto } from '@pflegenavi/shared/api';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';

interface ReceiptBatchCommonProps<T extends ReceiptBatchFormValues> {
  renderReceiptBatchCommonForm: (
    formik: FormikProps<T>,
    recurringItem: RecurringItemDto | undefined
  ) => React.ReactElement;
  renderReceiptBatchImageInput: (formik: FormikProps<T>) => React.ReactElement;
  renderCashLink?: (formik: FormikProps<T>) => React.ReactElement;
  recurringItem: RecurringItemDto | undefined;
}

export function ReceiptBatchCommonContainer<T extends ReceiptBatchFormValues>({
  renderReceiptBatchCommonForm,
  renderReceiptBatchImageInput,
  renderCashLink,
  recurringItem,
}: ReceiptBatchCommonProps<T>): React.ReactElement {
  const formik = useFormikContext<T>();

  const cashListEnabled =
    useFeatureFlag(FeatureFlag.CoinList) === FeatureFlagStatus.Enabled;

  const ReceiptBatchCommonForm = useMemo(() => {
    return renderReceiptBatchCommonForm(formik, recurringItem);
  }, [formik, recurringItem, renderReceiptBatchCommonForm]);

  const ReceiptBatchImageInput = useMemo(() => {
    return renderReceiptBatchImageInput(formik);
  }, [formik, renderReceiptBatchImageInput]);

  const CashLink = useMemo(() => {
    if (!renderCashLink || !cashListEnabled) {
      return null;
    }
    return renderCashLink(formik);
  }, [formik, renderCashLink, cashListEnabled]);

  return (
    <ReceiptBatchV2CommonView
      receiptsCount={formik.values.receipts.length}
      totalAmount={formik.values.receipts.reduce(
        (acc, receipt) => acc + (receipt.amount ?? 0),
        0
      )}
      ReceiptBatchCommonForm={ReceiptBatchCommonForm}
      ReceiptBatchImageInput={ReceiptBatchImageInput}
      CashLink={CashLink}
    />
  );
}
