import type { State } from './quickResidentSelectionReducer';
import type { ResidentType } from './ReceiptBatchQuickResidentSelectionForm';

export const selectLeftArrowDisabled = (state: State): boolean => {
  return state.filteredStagedForDeselection.length === 0;
};

export const selectRightArrowDisabled = (state: State): boolean => {
  return state.staged.length === 0;
};

export const selectNonSelected = (state: State): ResidentType[] =>
  state.nonSelected;

export const selectStaged = (state: State): ResidentType[] => state.staged;

export const selectFilteredStagedForDeselection = (
  state: State
): ResidentType[] => state.filteredStagedForDeselection;

export const selectFilteredNonSelected = (state: State): ResidentType[] =>
  state.filteredNonSelected;

export const selectFilteredSelected = (state: State): ResidentType[] =>
  state.filteredSelected;

export const selectReceiptAmount = (state: State): number | undefined =>
  state.receiptAmount;
