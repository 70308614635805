import type { FC } from 'react';
import React from 'react';
import { useEffect } from 'react';
import { useAuthentication } from '@pflegenavi/frontend/authentication';
import { CircularProgress } from '@mui/material';
import { LoadingContainer } from '@pflegenavi/web-components';

export const RedirectResetPassword: FC = () => {
  const auth = useAuthentication();

  useEffect(() => {
    auth?.logout();
    // Only logout on mount
    // eslint-disable-next-line @grncdr/react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingContainer>
      <CircularProgress variant="indeterminate" />
    </LoadingContainer>
  );
};
