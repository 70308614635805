import type { FC } from 'react';
import React, { memo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import type { ResidentType } from './ReceiptBatchQuickResidentSelectionForm';

interface SelectResidentRowProps {
  residentId: string;
  residentName: string;
  residentFirstName: string;
  residentLastName: string;
  checked: boolean;
  onClick: (resident: ResidentType, checked: boolean) => void;
}

export const SelectResidentRow: FC<SelectResidentRowProps> = memo(
  ({
    residentId,
    residentName,
    residentFirstName,
    residentLastName,
    checked,
    onClick,
  }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e, checked) => {
              onClick(
                {
                  id: residentId,
                  name: residentName,
                  firstName: residentFirstName,
                  lastName: residentLastName,
                },
                checked
              );
            }}
            sx={{
              '&.Mui-checked': {
                color: 'info.main',
              },
            }}
          />
        }
        label={residentName}
      />
    );
  }
);
