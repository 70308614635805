import type { FC } from 'react';
import React from 'react';
import { Link, Stack, Typography } from '@mui/material';

interface FooterProps {
  supportEmail: string;
}

export const Footer: FC<FooterProps> = ({ supportEmail }) => {
  return (
    <Stack alignItems="center" mt={10}>
      <Link href={`mailto:${supportEmail}`}>
        <Typography>{supportEmail}</Typography>
      </Link>
      <Typography variant="subtitle1">
        © {new Date().getFullYear()} Pflegenavi GmbH
      </Typography>
    </Stack>
  );
};
