import { isDefined } from '@pflegenavi/shared/utils';

export type ServerBatch = UpdateBatchEntries[];

export interface UpdateBatchEntries {
  id?: string;
  receiptImageIds?: string[] | null;
}

interface UseUpdateReceiptBatchWithMergeBaseParams {
  oldBatch: UpdateBatchEntries[];
  newBatch: UpdateBatchEntries[];
  entries: UpdateBatchEntries[];
}

// This function updates `entries` and add `receiptImageIds` if they were added on the server. Or removes them
// if they were removed locally.
export const updateReceiptBatchWithMergeBase = (
  opts: UseUpdateReceiptBatchWithMergeBaseParams
): UpdateBatchEntries[] => {
  const {
    oldBatch: oldServerBatch,
    newBatch: newServerBatch,
    entries: localEntries,
  } = opts;

  // eslint-disable-next-line complexity
  const localUpdated = localEntries.map((localEntry) => {
    const oldServerEntry = oldServerBatch.find(
      (entry) => entry.id === localEntry.id
    );
    const newServerEntry = newServerBatch.find(
      (entry) => entry.id === localEntry.id
    );

    if (!oldServerEntry || !newServerEntry) {
      return {
        ...localEntry,
        receiptImageIds: localEntry.receiptImageIds ?? [],
      };
    }

    const oldServerImageIds = oldServerEntry.receiptImageIds || [];
    const newServerImageIds = newServerEntry.receiptImageIds || [];
    const localImageIds = localEntry.receiptImageIds || [];

    const addedImageIds = newServerImageIds.filter(
      (id) => !oldServerImageIds.includes(id)
    );

    const mergedImageIds = [...localImageIds, ...addedImageIds];

    return {
      ...localEntry,
      receiptImageIds: mergedImageIds,
    };
  });

  const deletedEntries = oldServerBatch.filter((oldServerEntry) => {
    return !localUpdated.find(
      (newServerEntry) => newServerEntry.id === oldServerEntry.id
    );
  });

  const localAdded = localUpdated.filter((localEntry) => {
    return (
      !oldServerBatch.find(
        (oldServerEntry) => oldServerEntry.id === localEntry.id
      ) &&
      !newServerBatch.find(
        (newServerEntry) => newServerEntry.id === localEntry.id
      )
    );
  });

  return newServerBatch
    .map((newServerEntry) => {
      const localEntry = localUpdated.find(
        (entry) => entry.id === newServerEntry.id
      );
      if (localEntry) {
        return localEntry;
      }
      if (deletedEntries.find((entry) => entry.id === newServerEntry.id)) {
        return undefined;
      }
      return newServerEntry;
    })
    .concat(localAdded)
    .filter(isDefined);
};
