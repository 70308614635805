import { Checkbox, FormControlLabel } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ShowAllResidentsProps {
  showAll: boolean;
  onChangeShowAll: (showAll: boolean) => void;
}

export const ShowAllResidents: FC<ShowAllResidentsProps> = memo(
  ({ showAll, onChangeShowAll }) => {
    const { t } = useTranslation();
    return (
      <FormControlLabel
        sx={{
          width: 'fit-content',
          '& .MuiTypography-body1': {
            mt: '1px',
          },
        }}
        control={
          <Checkbox
            checked={showAll}
            onChange={(e, checked) => {
              onChangeShowAll(checked);
            }}
            sx={{
              '&.Mui-checked': {
                color: 'info.main',
                '& .Mui-disabled': {
                  color: 'error.main',
                },
              },
            }}
          />
        }
        label={t(
          'receipts.batch.form.resident-quick-selection.show-all-residents'
        )}
        labelPlacement="start"
      />
    );
  }
);
